/**
 * @generated SignedSource<<7d466d7ef34afe4f83f7370a13e88c9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemRoles = "ADMIN" | "HOUSEKEEPING" | "MANAGER" | "RECEPTION" | "RESERVATION" | "%future added value";
export type UserRoleInsert = {
  applications?: ReadonlyArray<number> | null | undefined;
  customResourceRights?: ReadonlyArray<ResourceRightsInput> | null | undefined;
  description?: string | null | undefined;
  name: string;
  systemRole?: SystemRoles | null | undefined;
};
export type ResourceRightsInput = {
  action: string;
  namespace: string;
  resource: string;
};
export type RoleInsertMutation$variables = {
  input: UserRoleInsert;
};
export type RoleInsertMutation$data = {
  readonly roleCreate: {
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
  };
};
export type RoleInsertMutation = {
  response: RoleInsertMutation$data;
  variables: RoleInsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "roleInsert",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "PayloadMessages_message",
    "selections": (v2/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleInsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserRoleUpdatePayload",
        "kind": "LinkedField",
        "name": "roleCreate",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "PayloadMessages_interface",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "warnings",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "PayloadInterface",
                "abstractKey": "__isPayloadInterface"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleInsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserRoleUpdatePayload",
        "kind": "LinkedField",
        "name": "roleCreate",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "type": "PayloadInterface",
            "abstractKey": "__isPayloadInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21171b65713858a58c86f4a9726bc908",
    "id": null,
    "metadata": {},
    "name": "RoleInsertMutation",
    "operationKind": "mutation",
    "text": "mutation RoleInsertMutation(\n  $input: UserRoleInsert!\n) {\n  roleCreate(roleInsert: $input) {\n    ...PayloadMessages_interface\n  }\n}\n\nfragment PayloadMessages_interface on PayloadInterface {\n  __isPayloadInterface: __typename\n  errors {\n    ...PayloadMessages_message\n  }\n  warnings {\n    ...PayloadMessages_message\n  }\n}\n\nfragment PayloadMessages_message on PayloadMessage {\n  code\n  text\n}\n"
  }
};
})();

(node as any).hash = "c35bf082cdb5ea2423d3fef9e8ef67be";

export default node;
