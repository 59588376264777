import { graphql, useLazyLoadQuery } from "react-relay";
import { Page, Panel, Suspense } from "h11-client-component-lib";
import { DashboardPageHotelInfoQuery } from "@relay-generated/DashboardPageHotelInfoQuery.graphql";

const hotelInfoFetch = graphql`
  query DashboardPageHotelInfoQuery($hotel: UUID!) {
    hotelGet(hoteluid: $hotel) {
      companyName
      hotelName
    }
  }
`;

function DashboardPanel() {
  // TODO Měl bych preferovat usePreloadedQuery
  const query = useLazyLoadQuery<DashboardPageHotelInfoQuery>(hotelInfoFetch, {
    hotel: "9de64306-2bb1-4d2b-aa5a-9a73c1e881c7",
  });
  return (
    <Panel label="Title" variant="compact">
      {query.hotelGet?.hotelName}
    </Panel>
  );
}

export function DashboardPage() {
  // noinspection HtmlUnknownTarget
  return (
    <Page variant="compact2" background="grey">
      <Suspense>
        <div
          style={{
            display: "grid",
            gap: 16,
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          }}>
          {[...Array(9)].map((_, index) => (
            <DashboardPanel key={index} />
          ))}
        </div>
      </Suspense>
    </Page>
  );
}
