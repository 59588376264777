import { FormDialog, useAppContext } from "h11-client-component-lib";
import { FormikProvider, useFormik } from "formik";
import {
  OwnPasswordChangeFormData,
  ownPasswordChangeSchema,
} from "../userData";
import { useTranslation } from "react-i18next";
import { readInlineData, useMutation } from "react-relay";
import { userChangeOwnPasswordMutation } from "./graphql/UserChangeOwnPasswordMutation";
import { UserChangeOwnPasswordMutation } from "@relay-generated/UserChangeOwnPasswordMutation.graphql";
import { useEffect } from "react";
import { useLoggedUser } from "@store";
import { OwnPasswordChangeForm } from "./forms/OwnPasswordChangeForm";
import { oAuthLoginLink } from "@comm/oauth";
import {
  payloadInterfaceFragment,
  payloadMessageFragment,
} from "@shared/graphql/PayloadMessages";
import { PayloadMessages_interface$key } from "@relay-generated/PayloadMessages_interface.graphql";
import { PayloadMessages_message$key } from "@relay-generated/PayloadMessages_message.graphql";

export function OwnPasswordChangeDialog({
  onClose,
  open,
}: {
  open: boolean;
  onClose: (submitted?: boolean) => void;
}) {
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();

  const { notify } = useAppContext();

  const [commit] = useMutation<UserChangeOwnPasswordMutation>(
    userChangeOwnPasswordMutation,
  );

  // FIXME tohle se taky docela opakuje... zobecnit?
  const formik = useFormik<OwnPasswordChangeFormData>({
    initialValues: {},
    validationSchema: ownPasswordChangeSchema,
    validateOnMount: true,
    onSubmit: values => {
      // TODO udělat abychom se vyhnuli opětovnému volání s stripUnknown
      // TODO Nahradit validate bez Sync
      const validInput = ownPasswordChangeSchema.validateSync(values, {
        stripUnknown: true,
      });

      // TODO sjednotit společné věci
      commit({
        variables: {
          input: {
            oldPassword: validInput.oldPassword,
            newPassword: validInput.newPassword,
          },
        },
        onCompleted: ({ userChangeOwnPassword }) => {
          const payloadData = readInlineData<PayloadMessages_interface$key>(
            payloadInterfaceFragment,
            userChangeOwnPassword,
          );
          const errors = payloadData.errors;
          if (errors?.length) {
            // FIXME kontrola dle kódu?
            const errorData = readInlineData<PayloadMessages_message$key>(
              payloadMessageFragment,
              errors[0],
            );
            notify(errorData.text, "warning");
            return;
          }

          notify(t("password_changed"), "success");
          onClose(true);

          // FIXME hotel z kontextu
          window.location.href = oAuthLoginLink(
            "9de64306-2bb1-4d2b-aa5a-9a73c1e881c7",
          );
        },
        // FIXME
        onError: error => alert("An error occurred:" + error),
      });
    },
  });

  //console.log(formik.values)

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setValues({}, true);
    }
  }, [open]);

  return (
    loggedUser && (
      <FormikProvider value={formik}>
        <FormDialog
          open={open}
          narrow
          centered
          onClose={() => onClose()}
          title={t(
            "password_change",
            `${loggedUser.firstName} ${loggedUser.lastName}`,
          )}
          onSubmit={formik.handleSubmit}>
          <OwnPasswordChangeForm dialogOpen={open} />
        </FormDialog>
      </FormikProvider>
    )
  );
}
