/**
 * @generated SignedSource<<858a9d6014dd92ea138dcfdce075b2f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileUploadRequest = {
  fileExtension: string;
  fileSize: number;
};
export type UserSetAvatarMutation$variables = {
  fileUpload: FileUploadRequest;
  userUid: string;
};
export type UserSetAvatarMutation$data = {
  readonly userSetAvatar: {
    readonly fileUploadResponse: {
      readonly uploadUrl: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
  };
};
export type UserSetAvatarMutation = {
  response: UserSetAvatarMutation$data;
  variables: UserSetAvatarMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileUpload"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userUid"
},
v2 = [
  {
    "kind": "Variable",
    "name": "fileUpload",
    "variableName": "fileUpload"
  },
  {
    "kind": "Variable",
    "name": "userUid",
    "variableName": "userUid"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FileUploadResponse",
  "kind": "LinkedField",
  "name": "fileUploadResponse",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uploadUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "PayloadMessages_message",
    "selections": (v4/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSetAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BoundAssetUploadPayload",
        "kind": "LinkedField",
        "name": "userSetAvatar",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "PayloadMessages_interface",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "warnings",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "PayloadInterface",
                "abstractKey": "__isPayloadInterface"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserSetAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BoundAssetUploadPayload",
        "kind": "LinkedField",
        "name": "userSetAvatar",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "PayloadInterface",
            "abstractKey": "__isPayloadInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fcc46f4d4cc16459a6e10598a859b04f",
    "id": null,
    "metadata": {},
    "name": "UserSetAvatarMutation",
    "operationKind": "mutation",
    "text": "mutation UserSetAvatarMutation(\n  $userUid: UUID!\n  $fileUpload: FileUploadRequest!\n) {\n  userSetAvatar(userUid: $userUid, fileUpload: $fileUpload) {\n    fileUploadResponse {\n      uploadUrl\n    }\n    ...PayloadMessages_interface\n  }\n}\n\nfragment PayloadMessages_interface on PayloadInterface {\n  __isPayloadInterface: __typename\n  errors {\n    ...PayloadMessages_message\n  }\n  warnings {\n    ...PayloadMessages_message\n  }\n}\n\nfragment PayloadMessages_message on PayloadMessage {\n  code\n  text\n}\n"
  }
};
})();

(node as any).hash = "3d4076cc1ac68f773dfbb64b9cbd7283";

export default node;
