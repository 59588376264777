/**
 * @generated SignedSource<<482e2388d83fd8641a342adb1e0b2a76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReservationsPageDummyQuery$variables = Record<PropertyKey, never>;
export type ReservationsPageDummyQuery$data = {
  readonly roomList: ReadonlyArray<{
    readonly code: string;
    readonly floor: {
      readonly code: string;
    } | null | undefined;
    readonly status: string;
  }>;
};
export type ReservationsPageDummyQuery = {
  response: ReservationsPageDummyQuery$data;
  variables: ReservationsPageDummyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Room",
    "kind": "LinkedField",
    "name": "roomList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BuildingFloor",
        "kind": "LinkedField",
        "name": "floor",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReservationsPageDummyQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReservationsPageDummyQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "16f48ce4ed4c273afc1cde32b69fd9c4",
    "id": null,
    "metadata": {},
    "name": "ReservationsPageDummyQuery",
    "operationKind": "query",
    "text": "query ReservationsPageDummyQuery {\n  roomList {\n    code\n    floor {\n      code\n    }\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf6a39d7a86c9740fe33aa0d87dbebcc";

export default node;
