/**
 * @generated SignedSource<<79b283f099e8a6900db9550b2d1d7630>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SystemRoles = "ADMIN" | "HOUSEKEEPING" | "MANAGER" | "RECEPTION" | "RESERVATION" | "%future added value";
export type RolesPageRolesQuery$variables = Record<PropertyKey, never>;
export type RolesPageRolesQuery$data = {
  readonly roleList: ReadonlyArray<{
    readonly _id: number;
    readonly name: string;
    readonly systemRole: SystemRoles | null | undefined;
  }>;
  readonly systemRoleList: ReadonlyArray<{
    readonly code: string;
    readonly name: string;
  }>;
};
export type RolesPageRolesQuery = {
  response: RolesPageRolesQuery$data;
  variables: RolesPageRolesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRole",
    "kind": "LinkedField",
    "name": "roleList",
    "plural": true,
    "selections": [
      {
        "alias": "_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemRole",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SystemUserRole",
    "kind": "LinkedField",
    "name": "systemRoleList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RolesPageRolesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RolesPageRolesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "55caa2c562f196102946ef99d36565e7",
    "id": null,
    "metadata": {},
    "name": "RolesPageRolesQuery",
    "operationKind": "query",
    "text": "query RolesPageRolesQuery {\n  roleList {\n    _id: id\n    name\n    systemRole\n  }\n  systemRoleList {\n    name\n    code\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea13c3a77adefc8785fb498e2912688b";

export default node;
