/**
 * @generated SignedSource<<95507acc890e4d7d34bacf5149177690>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoleInsertDialogQuery$variables = Record<PropertyKey, never>;
export type RoleInsertDialogQuery$data = {
  readonly fileStoreResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly hotelsResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly reservationResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly systemRoleList: ReadonlyArray<{
    readonly code: string;
    readonly name: string;
  }>;
  readonly usersResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
};
export type RoleInsertDialogQuery = {
  response: RoleInsertDialogQuery$data;
  variables: RoleInsertDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "SystemUserRole",
  "kind": "LinkedField",
  "name": "systemRoleList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserRightsResource_resource"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "namespace",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "resource",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRightsAction",
    "kind": "LinkedField",
    "name": "actions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "action",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemRoles",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleInsertDialogQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "hotelsResourcesDefinition",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "fileStoreResourcesDefinition",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "usersResourcesDefinition",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "reservationResourcesDefinition",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoleInsertDialogQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "hotelsResourcesDefinition",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "fileStoreResourcesDefinition",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "usersResourcesDefinition",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "reservationResourcesDefinition",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "001571be2fd24be21166e6926ee1c264",
    "id": null,
    "metadata": {},
    "name": "RoleInsertDialogQuery",
    "operationKind": "query",
    "text": "query RoleInsertDialogQuery {\n  systemRoleList {\n    code\n    name\n  }\n  hotelsResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  fileStoreResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  usersResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  reservationResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n}\n\nfragment UserRightsResource_resource on UserRightsResource {\n  namespace\n  resource\n  actions {\n    action\n    title\n    description\n    systemRoles\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e72a3061a6ca71b08ae5a11f9c11c60";

export default node;
