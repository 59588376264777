import { graphql } from "react-relay";

export const payloadInterfaceFragment = graphql`
  fragment PayloadMessages_interface on PayloadInterface @inline {
    errors {
      ...PayloadMessages_message
    }
    warnings {
      ...PayloadMessages_message
    }
  }
`;

export const payloadMessageFragment = graphql`
  fragment PayloadMessages_message on PayloadMessage @inline {
    code
    text
  }
`;
