import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";
import { Switch, SwitchGroup } from "h11-client-component-lib";

export function NotificationsGroupsPanel({ style }: { style?: CSSProperties }) {
  const { t } = useTranslation();

  // FIXME

  return (
    <SwitchGroup label={t("notification_groups")} style={style}>
      <Switch
        id="management-todo"
        checked
        onChange={() => {}}
        label="Management"
      />
      <Switch
        id="front-office-todo"
        checked
        onChange={() => {}}
        label="Front office"
      />
      <Switch id="technici-todo" checked onChange={() => {}} label="Technici" />
    </SwitchGroup>
  );
}
