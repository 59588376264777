/**
 * @generated SignedSource<<66df28b98a68bf5564c808659c1fd681>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SystemRoles = "ADMIN" | "HOUSEKEEPING" | "MANAGER" | "RECEPTION" | "RESERVATION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserRightsResource_resource$data = ReadonlyArray<{
  readonly actions: ReadonlyArray<{
    readonly action: string;
    readonly description: string;
    readonly systemRoles: ReadonlyArray<SystemRoles>;
    readonly title: string;
  }>;
  readonly namespace: string;
  readonly resource: string;
  readonly " $fragmentType": "UserRightsResource_resource";
}>;
export type UserRightsResource_resource$key = ReadonlyArray<{
  readonly " $data"?: UserRightsResource_resource$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserRightsResource_resource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namespace",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resource",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRightsAction",
      "kind": "LinkedField",
      "name": "actions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemRoles",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserRightsResource",
  "abstractKey": null
};

(node as any).hash = "a58e4a194278e2431ee8899195cc2224";

export default node;
