import { BFF_URI } from "../params";
import {
  Environment,
  Network,
  RecordSource,
  Store,
  Variables,
} from "relay-runtime";
import { RequestParameters } from "relay-runtime/lib/util/RelayConcreteNode";

export const loginNeededHttpStatuses = [401, 403];

export class LoginNeededError extends Error {}

async function fetchFunction(
  operation: RequestParameters,
  variables: Variables,
) {
  const data = await fetch(`${BFF_URI}/api`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });

  console.debug(
    JSON.stringify({
      query: operation.text,
      variables,
    }),
  );

  // TODO https://react.dev/learn/react-developer-tools

  if (data.ok) {
    return await data.json();
  } else if (loginNeededHttpStatuses.includes(data.status)) {
    throw new LoginNeededError();
  } else {
    throw Error("Invalid response: " + data.status);
  }
}

export const relayEnvironment = new Environment({
  network: Network.create(fetchFunction),
  store: new Store(new RecordSource()),
});
