// NICETOHAVE není cryptographically strong
import { BaseQuery, FetchCall, MutationCall, prepareApiCall } from "@comm/comm";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { userSetAvatarMutation } from "./forms/graphql/UserSetAvatarMutation";
import { createFileUploadParameters } from "@shared/utils/fileUtils";
import { UserFormData } from "./userData";
import { UserSetAvatarMutation } from "@relay-generated/UserSetAvatarMutation.graphql";
import { BFF_URI } from "../../params";

export function generateStrongPassword(): string {
  const specialCharacters = "._-+*";
  const numbers = "1234567890";
  const lowercaseAlphabet = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseAlphabet = lowercaseAlphabet.toUpperCase();

  const getRandomCharacter = (chars: string): string => {
    return chars[Math.floor(Math.random() * chars.length)];
  };

  // Generate password with at least one of each character type
  let password =
    getRandomCharacter(specialCharacters) +
    getRandomCharacter(numbers) +
    getRandomCharacter(lowercaseAlphabet) +
    getRandomCharacter(uppercaseAlphabet);

  // Add random characters till password length is 10
  const allCharacters = numbers + lowercaseAlphabet + uppercaseAlphabet;
  while (password.length < 10) {
    password += getRandomCharacter(allCharacters);
  }

  // Randomize password characters order
  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  return password;
}

export type UserChainResponsesType = {
  "user-set-avatar": {
    uploadUrl: string;
  };
  main: {
    userUid: string;
  };
};

export type RobotUserChainResponsesType = {
  "user-set-avatar": {
    uploadUrl: string;
  };
  main: {
    userUid: string;
    token: string;
  };
};

export function executeUserCall<
  T extends BaseQuery,
  R extends UserChainResponsesType,
>(
  primaryCall: MutationCall<T, R>,
  values: UserFormData,
  userUidExtractor: (responses: Partial<UserChainResponsesType>) => string,
  relayEnvironment: RelayModernEnvironment,
) {
  const call = prepareApiCall(primaryCall);

  const { avatarFile } = values;
  if (avatarFile instanceof File) {
    const userSetAvatarCall: MutationCall<
      UserSetAvatarMutation,
      UserChainResponsesType
    > = {
      id: "user-set-avatar",
      environment: relayEnvironment,
      mutation: userSetAvatarMutation,
      variables: responses => ({
        userUid: userUidExtractor(responses),
        fileUpload: createFileUploadParameters(avatarFile),
      }),
      validateAndExtract: result => {
        const uploadUrl = result.userSetAvatar.fileUploadResponse?.uploadUrl;
        return uploadUrl ? { uploadUrl } : false;
      },
    };

    const avatarUploadCall = createAvatarUploadCall(
      avatarFile,
      responses => responses["user-set-avatar"]!.uploadUrl,
    );

    call.chain(userSetAvatarCall).chain(avatarUploadCall);
  }

  return call.execute();
}

export function createAvatarUploadCall(
  newAvatarFile: File,
  uploadUrlExtractor: (responses: Partial<UserChainResponsesType>) => string,
): FetchCall<UserChainResponsesType> {
  return {
    fetch: async responses => {
      const uploadUrl = uploadUrlExtractor(responses);

      console.dir(responses);

      const formData = new FormData();

      formData.append("file", newAvatarFile);

      const r = await fetch(`${BFF_URI}/upload`, {
        method: "POST",
        body: formData,
        headers: {
          "x-upload-url": uploadUrl,
        },
      });

      if (!r.ok) {
        throw Error("Invalid status: " + r.status);
      }
    },
  };
}
