import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentUserFragment$data } from "@relay-generated/CurrentUserFragment.graphql";

export type CurrentUserDetail = CurrentUserFragment$data;

export interface UserState {
  value?: CurrentUserDetail;

  // loadSeq is used for currentUser refresh - if the number is higher than the last one, LoggedUserProvider reloads current user
  loadSeq: number;
}

const initialState: UserState = {
  loadSeq: 1,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedUser(state, action: PayloadAction<CurrentUserDetail>) {
      state.value = action.payload;
    },
    refreshLoggedUser(state) {
      ++state.loadSeq;
    },
  },
});

export const { setLoggedUser, refreshLoggedUser } = userSlice.actions;
export const reducer = userSlice.reducer;
