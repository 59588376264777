import {
  IconButton,
  ListActionsContainer,
  Page,
  Panel,
  SearchField,
  Suspense,
  Table,
  useAppContext,
  useFilterAndHighlight,
} from "h11-client-component-lib";
import { useEffect, useMemo, useState } from "react";
import {
  graphql,
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import { ColumnDef } from "@tanstack/react-table";
import { InfoCircle, Pencil, PlusCircle, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { RoleTableItem } from "./roleData";
import { RolesPageRolesQuery } from "@relay-generated/RolesPageRolesQuery.graphql";
import "./index.scss";
import { RoleInsertDialog } from "./RoleInsertDialog";
import { roleInsertDialogQuery } from "./graphql/RoleInsertDialogQuery";
import { roleUpdateDialogQuery } from "./graphql/RoleUpdateDialogQuery";
import { RoleUpdateDialogQuery } from "@relay-generated/RoleUpdateDialogQuery.graphql";
import { RoleUpdateDialog } from "./RoleUpdateDialog";
import { RoleInsertDialogQuery } from "@relay-generated/RoleInsertDialogQuery.graphql";
import { RolesPageUserDeleteMutation } from "@relay-generated/RolesPageUserDeleteMutation.graphql";

const rolesQuery = graphql`
  query RolesPageRolesQuery {
    roleList {
      _id: id
      name
      systemRole
    }
    systemRoleList {
      name
      code
    }
  }
`;

export const roleDeleteMutation = graphql`
  mutation RolesPageUserDeleteMutation($id: Int!) {
    roleDelete(roleId: $id)
  }
`;

function RolesTable({
  queryRef,
  onRowAction,
  search,
}: {
  queryRef: PreloadedQuery<RolesPageRolesQuery>;
  onRowAction: (action: "edit" | "delete", user: RoleTableItem) => void;
  search: string;
}) {
  const { t } = useTranslation();

  const data = usePreloadedQuery(rolesQuery, queryRef);

  const roles = useMemo(() => [...data.roleList!], [data]);
  const systemRoles = useMemo(() => data.systemRoleList!, [data]);

  const filteredRoles = useFilterAndHighlight(roles, search, "name");

  // FIXME UserRole, pokud je instance of Node, by měl mít stringové id (typ ID) - ověřit s Brychem?

  // TODO Nastává mi tady problém, když mám všechny properties any
  const columns: ColumnDef<(typeof filteredRoles)[number]>[] = [
    {
      accessorKey: "name",
      header: t("name"),
      cell: c => c.row.original.nameHighlighted,
    },
    {
      header: t("system_role"),
      accessorKey: "systemRole",
      cell: r => systemRoles.find(s => s.code === r.getValue())?.name,
    },
    {
      id: "info",
      header: () => <div className="center">{t("info")}</div>,
      enableSorting: false,
      cell: () => (
        <div className="center">
          <IconButton onClick={() => alert("TODO")}>
            <InfoCircle />
          </IconButton>
        </div>
      ),
    },
    {
      id: "edit",
      header: () => <div className="center">{t("edit")}</div>,
      cell: c => (
        <div className="center">
          <IconButton onClick={() => onRowAction("edit", c.row.original)}>
            <Pencil />
          </IconButton>
        </div>
      ),
    },
    {
      id: "delete",
      header: () => <div className="center">{t("delete")}</div>,
      cell: c => (
        <div className="center">
          <IconButton onClick={() => onRowAction("delete", c.row.original)}>
            <Trash />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      defaultSort={{ id: "name", desc: false }}
      data={filteredRoles}
    />
  );
}

// FIXME možná všechny tyhle obrazovky zobecnit? Protože jsou stejné ve smyslu, že mají jednu tabulku a zobrazují dialogy

export function RolesPage() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [openedDialog, setOpenedDialog] = useState<"edit" | "new">();

  const appContext = useAppContext();

  const [queryRef, loadQuery] = useQueryLoader<RolesPageRolesQuery>(rolesQuery);

  const [insertDialogQueryRef, loadInsertDialogQuery] =
    useQueryLoader<RoleInsertDialogQuery>(roleInsertDialogQuery);

  const [updateDialogQueryRef, loadUpdateDialogQuery] =
    useQueryLoader<RoleUpdateDialogQuery>(roleUpdateDialogQuery);

  const [roleDeleteMutationCommit] =
    useMutation<RolesPageUserDeleteMutation>(roleDeleteMutation);

  const { notify } = useAppContext();

  function refresh() {
    loadQuery({}, { fetchPolicy: "store-and-network" });
  }

  function deleteRole({ _id, name }: RoleTableItem) {
    appContext
      .confirm(t("delete_role_question", { name: `${name}` }))
      .then(confirmed => {
        if (confirmed) {
          roleDeleteMutationCommit({
            variables: { id: _id },
            // TODO nějaká notifikace
            onCompleted: (response, errors) => {
              notify(t("role_deleted"), "success");
              refresh();
            },
            // FIXME
            onError: error => alert("An error occurred:" + error),
          });
        }
      });
  }

  useEffect(() => {
    refresh();
  }, []);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Page background="grey" className="roles-page" compress>
        <Panel label={t("roles_list")}>
          <ListActionsContainer
            search={
              <SearchField
                value={search}
                onChange={setSearch}
                placeholder={t("role_search_placeholder")}
              />
            }
            actions={[
              {
                id: "create_new_role",
                title: t("create_new_role"),
                icon: <PlusCircle />,
                action: () => {
                  setOpenedDialog("new");
                  loadInsertDialogQuery({});
                },
              },
            ]}>
            <Suspense>
              {queryRef && (
                <RolesTable
                  queryRef={queryRef}
                  search={search}
                  onRowAction={(action, role) => {
                    switch (action) {
                      case "edit":
                        setOpenedDialog("edit");
                        loadUpdateDialogQuery(
                          { id: role._id },
                          { fetchPolicy: "network-only" },
                        );
                        break;
                      case "delete":
                        deleteRole(role);
                        break;
                    }
                  }}
                />
              )}
            </Suspense>
          </ListActionsContainer>
        </Panel>
      </Page>
      {insertDialogQueryRef && (
        <RoleInsertDialog
          open={openedDialog === "new"}
          onClose={submitted => {
            setOpenedDialog(undefined);
            if (submitted) {
              refresh();
            }
          }}
          queryRef={insertDialogQueryRef}
        />
      )}
      {updateDialogQueryRef && (
        <RoleUpdateDialog
          queryRef={updateDialogQueryRef}
          open={openedDialog === "edit"}
          onClose={submitted => {
            setOpenedDialog(undefined);
            if (submitted) {
              refresh();
            }
          }}
        />
      )}
    </>
  );
}
