// TODO potom nahradit dle prostředí
import { BFF_URI, H11_URI, OAUTH_CLIENT_ID } from "../params";

const redirectUri = `${H11_URI}/login/callback`;

// TODO state se nám vrátí v callbacku - můžu použít pro ochranu proti CSRF (random string, který si uložím do session na serveru a pak porovnám)
const state = "123456";

export const oAuthLoginLink = (hotelId: string) =>
  `https://hsp.horesplus.com/oauth2/authorize?response_type=code&client_id=${OAUTH_CLIENT_ID}&state=${state}&scope=${hotelId}&redirect_uri=${redirectUri}`;

export async function logout(hotelId: string) {
  const logoutRes = await fetch(`${BFF_URI}/logout`, {
    method: "POST",
    credentials: "same-origin",
  });

  if (!logoutRes.ok) {
    throw new Error("Cannot logout");
  }

  window.location.href = oAuthLoginLink(hotelId);
}
