import { useTranslation } from "react-i18next";
import { FormikProvider } from "formik";
import { userBaseSchema, UserFormData } from "./userData";
import { useEffect, useState } from "react";
import { FormDialog, useAppContext } from "h11-client-component-lib";
import { UserForm } from "./forms/UserForm";
import { RobotUserTokenDialog } from "./RobotUserTokenDialog";
import { useApiForm } from "@shared/forms/apiForm";
import {
  executeUserCall,
  RobotUserChainResponsesType,
  UserChainResponsesType,
} from "./userUtil";
import { MutationCall } from "@comm/comm";
import { robotUserInsertMutation } from "./graphql/RobotUserInsertMutation";
import { RobotUserInsertMutation } from "@relay-generated/RobotUserInsertMutation.graphql";

const robotUserInsertSchema = userBaseSchema.clone().omit(["tel", "email"]);

// NICETOHAVE Sjednotit s UserInsertDialog
export function RobotUserInsertDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (submitted?: boolean) => void;
}) {
  const { t } = useTranslation();

  const [newToken, setNewToken] = useState<string>();

  const { notify } = useAppContext();

  // FIXME disable formuláře pokud submitting - nějaký overlay?
  const { formik, submitting } = useApiForm<
    UserFormData,
    typeof robotUserInsertSchema,
    UserChainResponsesType
  >({
    initialValues: {},
    messages: {
      success: t("user_saved"),
      withWarnings: t("user_saved_with_errors"),
    },
    resetDeps: [open],
    schema: robotUserInsertSchema,
    onSubmit: (values, relayEnvironment) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { avatarFile, ...valuesWithoutAvatarFile } = values;
      const userInsertCall: MutationCall<
        RobotUserInsertMutation,
        RobotUserChainResponsesType
      > = {
        id: "main",
        environment: relayEnvironment,
        mutation: robotUserInsertMutation,
        variables: {
          input: valuesWithoutAvatarFile,
        },
        validateAndExtract: ({ robotUserCreate: { user, token } }) => {
          if (user && token) {
            return {
              userUid: user.userUid,
              token,
            };
          } else {
            return false;
          }
        },
      };

      return executeUserCall(
        userInsertCall,
        values,
        responses => responses["main"]!.userUid,
        relayEnvironment,
      );
    },
    onSuccess: () => {
      onClose(true);
    },
  });

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setValues({}, true);
    }
  }, [open]);

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose()}
        title={t("robot_user_insert")}>
        <UserForm formik={formik} robotUser />
      </FormDialog>
      {newToken && (
        <RobotUserTokenDialog
          token={newToken}
          onClose={() => {
            onClose(true);
            setNewToken(undefined);
          }}
        />
      )}
    </FormikProvider>
  );
}
