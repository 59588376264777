import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { FormikProvider } from "formik";
import { userBaseSchema, UserFormData } from "./userData";
import { UserForm } from "./forms/UserForm";
import { FormDialog } from "h11-client-component-lib";
import { MutationCall } from "@comm/comm";
import { userInsertMutation } from "./forms/graphql/UserInsertMutation";
import { UserInsertMutation } from "@relay-generated/UserInsertMutation.graphql";
import { useApiForm } from "@shared/forms/apiForm";
import { executeUserCall, UserChainResponsesType } from "./userUtil";

const userInsertSchema = userBaseSchema.clone().shape({
  password: yup.string().required(),
});

export function UserInsertDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (submitted?: boolean) => void;
}) {
  const { t } = useTranslation();

  // FIXME disable formuláře pokud submitting - nějaký overlay?
  const { formik, submitting } = useApiForm<
    UserFormData,
    typeof userInsertSchema,
    UserChainResponsesType
  >({
    initialValues: {},
    messages: {
      success: t("user_saved"),
      withWarnings: t("user_saved_with_errors"),
    },
    resetDeps: [open],
    schema: userInsertSchema,
    onSubmit: (values, relayEnvironment) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { avatarFile, ...valuesWithoutAvatarFile } = values;
      const userInsertCall: MutationCall<
        UserInsertMutation,
        UserChainResponsesType
      > = {
        id: "main",
        environment: relayEnvironment,
        mutation: userInsertMutation,
        variables: {
          input: valuesWithoutAvatarFile,
        },
        validateAndExtract: result => {
          const userUid = result.userCreate.user?.userUid;
          return userUid ? { userUid } : false;
        },
      };

      return executeUserCall(
        userInsertCall,
        values,
        responses => responses["main"]!.userUid,
        relayEnvironment,
      );
    },
    onSuccess: () => {
      onClose(true);
    },
  });

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose()}
        title={t("user_insert")}>
        <UserForm formik={formik} />
      </FormDialog>
    </FormikProvider>
  );
}
