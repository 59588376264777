import {
  useFilterAndHighlight,
  IconButton,
  ListActionsContainer,
  Page,
  Panel,
  SearchField,
  Suspense,
  Switch,
  Table,
  useAppContext,
} from "h11-client-component-lib";
import { useEffect, useMemo, useState } from "react";
import {
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import { ColumnDef } from "@tanstack/react-table";
import {
  Check2,
  InfoCircle,
  Pencil,
  PlusCircle,
  Trash,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { UserTableItem } from "./userData";
import { useLoggedUser } from "@store";
import { UserUpdateDialog } from "./UserUpdateDialog";
import { RobotUserInsertDialog } from "./RobotUserInsertDialog";
import { UserInsertDialog } from "./UserInsertDialog";
import { usersQuery } from "./graphql/UsersQuery";
import { UsersQuery } from "@relay-generated/UsersQuery.graphql";
import { userQuery } from "./graphql/UserQuery";
import { userActiveMutation } from "./graphql/UserActiveMutation";
import { userDeleteMutation } from "./graphql/UserDeleteMutation";
import { UserActiveMutation } from "@relay-generated/UserActiveMutation.graphql";
import { UserDeleteMutation } from "@relay-generated/UserDeleteMutation.graphql";
import { UserQuery } from "@relay-generated/UserQuery.graphql";
import { UserAvatar } from "@shared/ui/UserAvatar";
import dayjs from "dayjs";

// FIXME rozdělit do více souborů

function UsersTable({
  onRowAction,
  queryRef,
  search,
}: {
  queryRef: PreloadedQuery<UsersQuery>;
  onRowAction: (
    action: "delete" | "edit" | "activate" | "deactivate",
    user: UserTableItem,
  ) => void;
  search: string;
}) {
  const { t } = useTranslation();
  const loggedUser = useLoggedUser();

  const query = usePreloadedQuery(usersQuery, queryRef);

  // TODO bez vykřičníku
  const users = useMemo(
    () =>
      [
        ...query.userList.map(u => ({
          ...u,
          avatarComponent: <UserAvatar user={u} size="small" noBorder />,
        })),
      ]!,
    [query],
  );

  const filteredUsers = useFilterAndHighlight(
    users,
    search,
    "firstName",
    "lastName",
    "code",
  );

  // FIXME UserRole, pokud je instance of Node, by měl mít stringové id (typ ID) - ověřit s Brychem?

  // TODO Nastává mi tady problém, když mám všechny properties any
  const columns: ColumnDef<(typeof filteredUsers)[number]>[] = [
    {
      accessorKey: "avatarComponent",
      header: "",
      meta: {
        cellClassName: "no-vertical-padding",
      },
      cell: ({ row: { original } }) => original.avatarComponent,
    },
    {
      accessorKey: "firstName",
      header: t("first_name"),
      sortingFn: "alphanumeric",
      cell: p => p.row.original.firstNameHighlighted,
    },
    {
      accessorKey: "lastName",
      header: t("last_name"),
      sortingFn: "alphanumeric",
      cell: p => p.row.original.lastNameHighlighted,
    },
    {
      accessorKey: "code",
      header: t("code"),
      sortingFn: "alphanumeric",
      cell: p => p.row.original.codeHighlighted,
    },
    {
      accessorKey: "userRoles",
      header: t("user_roles_short"),
      cell: p =>
        !p.getValue() ? (
          <div className="center">
            <Check2 />
          </div>
        ) : null,
    },
    {
      accessorKey: "validTo",
      header: t("valid_to"),
      cell: p => {
        const date = dayjs(p.getValue() as string);
        return (
          // TODO barvu z knihovny - $error2
          // TODO class, ne style
          // FIXME formát datumu dle lokalizace
          <span
            style={
              date.isBefore(dayjs(), "day")
                ? { color: "rgb(204,0,0)" }
                : undefined
            }>
            {date.format("DD.MM.YYYY")}
          </span>
        );
      },
    },
    {
      accessorKey: "interactiveLogin",
      header: t("technical_user"),
      cell: p =>
        !p.getValue() ? (
          <div className="center">
            <Check2 />
          </div>
        ) : null,
    },
    {
      id: "info",
      header: () => <div className="center">{t("info")}</div>,
      enableSorting: false,
      cell: () => (
        <div className="center">
          <IconButton onClick={() => alert("TODO")}>
            <InfoCircle />
          </IconButton>
        </div>
      ),
    },
    {
      id: "active",
      header: () => <div className="center">{t("active")}</div>,
      meta: {
        cellClassName: "ignore-inactive, no-vertical-padding",
      },
      cell: p => (
        <div className="center">
          <Switch
            id={`${p.row.original.userUid}-active`}
            onChange={checked => {
              onRowAction(checked ? "activate" : "deactivate", p.row.original);
            }}
            disabled={p.row.original.userUid === loggedUser?.userUid}
            checked={p.row.original.active}
          />
        </div>
      ),
    },
    {
      id: "edit",
      header: () => <div className="center">{t("edit")}</div>,
      cell: c => (
        <div className="center">
          <IconButton onClick={() => onRowAction("edit", c.row.original)}>
            <Pencil />
          </IconButton>
        </div>
      ),
    },
    {
      id: "delete",
      header: () => <div className="center">{t("delete")}</div>,
      cell: p => (
        <div className="center">
          <IconButton
            onClick={() => onRowAction("delete", p.row.original)}
            disabled={p.row.original.userUid === loggedUser?.userUid}>
            <Trash />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={filteredUsers}
      defaultSort={{ id: "firstName", desc: false }}
      trProps={d => (!d.active ? { className: "inactive" } : {})}
    />
  );
}

export function UsersPage() {
  const { t } = useTranslation();
  const [showInactive, setShowInactive] = useState(false);
  const [search, setSearch] = useState("");
  const [openedDialog, setOpenedDialog] = useState<
    "edit" | "new" | "new_robot"
  >();

  const appContext = useAppContext();

  const [queryRef, loadQuery] = useQueryLoader<UsersQuery>(usersQuery);

  const { notify } = useAppContext();

  const [updateDialogQueryRef, loadUpdateDialogQuery] =
    useQueryLoader<UserQuery>(userQuery);

  const [userActiveMutationCommit] =
    useMutation<UserActiveMutation>(userActiveMutation);

  const [userDeleteMutationCommit] =
    useMutation<UserDeleteMutation>(userDeleteMutation);

  function refresh() {
    loadQuery(
      { active: showInactive ? null : true },
      { fetchPolicy: "store-and-network" },
    );
  }

  useEffect(() => {
    refresh();
  }, [showInactive]);

  function deleteUser({ firstName, lastName, userUid }: UserTableItem) {
    appContext
      .confirm(
        t("delete_user_question", { userName: `${firstName} ${lastName}` }),
      )
      .then(confirmed => {
        if (confirmed) {
          userDeleteMutationCommit({
            variables: { userUid },
            // TODO nějaká notifikace
            onCompleted: (response, errors) => {
              notify(t("user_deleted"), "success");
              refresh();
            },
            // FIXME
            onError: error => alert("An error occurred:" + error),
          });
        }
      });
  }

  function changeUserActive(
    { firstName, lastName, userUid }: UserTableItem,
    active: boolean,
  ) {
    appContext
      .confirm(
        t("deactivate_user_question", { userName: `${firstName} ${lastName}` }),
        active,
      )
      .then(confirmed => {
        if (confirmed) {
          userActiveMutationCommit({
            variables: { userUid, active },
            // TODO nějaká notifikace
            onCompleted: (response, errors) => {
              notify(
                t(active ? "user_activated" : "user_deactivated"),
                "success",
              );
              refresh();
            },
            // FIXME
            onError: error => alert("An error occurred:" + error),
          });
        }
      });
  }

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Page background="grey" compress>
        <Panel label={t("users_list")}>
          <ListActionsContainer
            search={
              <SearchField
                value={search}
                onChange={setSearch}
                placeholder={t("user_search_placeholder")}
              />
            }
            inactive={{
              id: "show-inactive-users",
              show: showInactive,
              onChange: setShowInactive,
            }}
            actions={[
              {
                id: "create_new_user",
                title: t("create_new_user"),
                icon: <PlusCircle />,
                action: () => {
                  setOpenedDialog("new");
                },
              },
              {
                id: "create_new_technical_user",
                title: t("create_new_technical_user"),
                icon: <PlusCircle />,
                action: () => {
                  setOpenedDialog("new_robot");
                },
              },
            ]}>
            <Suspense>
              {queryRef && (
                <UsersTable
                  search={search}
                  queryRef={queryRef}
                  onRowAction={(action, user) => {
                    switch (action) {
                      case "edit":
                        setOpenedDialog("edit");
                        loadUpdateDialogQuery(
                          { id: user.userUid },
                          { fetchPolicy: "network-only" },
                        );
                        break;
                      case "activate":
                      case "deactivate":
                        changeUserActive(user, action === "activate");
                        break;
                      case "delete":
                        deleteUser(user);
                        break;
                    }
                  }}
                />
              )}
            </Suspense>
          </ListActionsContainer>
        </Panel>
      </Page>
      {/*TODO suspense asi jinam, aby zobrazil loading už v dialogu?*/}
      {/*TODO nemohli / neměli by být dialogy nějak zkombinované? Alespoň insert/update?*/}
      <UserInsertDialog
        open={openedDialog === "new"}
        onClose={submitted => {
          setOpenedDialog(undefined);
          if (submitted) {
            refresh();
          }
        }}
      />
      <RobotUserInsertDialog
        open={openedDialog === "new_robot"}
        onClose={submitted => {
          setOpenedDialog(undefined);
          if (submitted) {
            refresh();
          }
        }}
      />
      {updateDialogQueryRef && (
        <UserUpdateDialog
          queryRef={updateDialogQueryRef}
          open={openedDialog === "edit"}
          onClose={submitted => {
            setOpenedDialog(undefined);
            if (submitted) {
              refresh();
            }
          }}
        />
      )}
    </>
  );
}
