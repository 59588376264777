import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { FormDialog, useAppContext } from "h11-client-component-lib";
import { RoleFormData, roleBaseSchema } from "./roleData";
import { RoleForm } from "./RoleForm";
import { roleInsertDialogQuery } from "./graphql/RoleInsertDialogQuery";
import { roleInsertMutation } from "./graphql/RoleInsertMutation";
import { RoleInsertDialogQuery } from "@relay-generated/RoleInsertDialogQuery.graphql";
import { RoleInsertMutation } from "@relay-generated/RoleInsertMutation.graphql";
import { useMergedResourcesDefinition } from "@shared/ui/resources/resourcesUtil";

export function RoleInsertDialog({
  open,
  onClose,
  queryRef,
}: {
  open: boolean;
  onClose: (submitted?: boolean) => void;
  queryRef: PreloadedQuery<RoleInsertDialogQuery>;
}) {
  const { t } = useTranslation();

  const [commit] = useMutation<RoleInsertMutation>(roleInsertMutation);

  const { notify } = useAppContext();

  const data = usePreloadedQuery(roleInsertDialogQuery, queryRef);

  const systemRoles = data.systemRoleList;

  const resourcesDefinitions = useMergedResourcesDefinition(data);

  const roleInsertSchema = useMemo(
    () => roleBaseSchema(systemRoles),
    [systemRoles],
  );

  const formik = useFormik<RoleFormData>({
    initialValues: {},
    validationSchema: roleInsertSchema,
    onSubmit: values => {
      const validInput = roleInsertSchema.validateSync(values, {
        stripUnknown: true,
      });

      commit({
        variables: {
          input: validInput,
        },
        onCompleted: () => {
          notify(t("role_saved"), "success");
          onClose(true);
        },
        // FIXME
        onError: error => alert("An error occurred:" + error),
      });
    },
  });

  console.log(formik.errors);

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setValues({}, true);
    }
  }, [open]);

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose()}
        title={t("role_insert")}>
        <RoleForm
          systemRoles={systemRoles}
          resourcesDefinitions={resourcesDefinitions}
        />
      </FormDialog>
    </FormikProvider>
  );
}
