import { FormikProps } from "formik";
import { UserFormData } from "../userData";
import {
  Button,
  FormGrid,
  FormikSwitch,
  Suspense,
  SwitchGroup,
  Tooltipped,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";
import { ArrowCounterclockwise, InfoCircle } from "react-bootstrap-icons";
import { RightsSettingsTab } from "@shared/ui/resources/RightsSettingsTab";
import { userRightsFormDataQuery } from "./graphql/UserRightsFormDataQuery";
import { UserRightsFormDataQuery } from "@relay-generated/UserRightsFormDataQuery.graphql";
import { isSameResourceRightsInput } from "@shared/data/ResourceRightsData";
import {
  useFixedResourceRights,
  useMergedResourcesDefinition,
} from "@shared/ui/resources/resourcesUtil";

export function UserRightsForm({
  formik,
}: {
  formik: FormikProps<UserFormData>;
}) {
  return (
    <Suspense>
      <UserRightsFormContent formik={formik} />
    </Suspense>
  );
}

// FIXME pokud se povolené aplikace také berou z rolí nemělo by to být na vnořené záložce?

function UserRightsFormContent({
  formik,
}: {
  formik: FormikProps<UserFormData>;
}) {
  const { t } = useTranslation();
  const data = useLazyLoadQuery<UserRightsFormDataQuery>(
    userRightsFormDataQuery,
    {},
  );

  const roles = data.roleList;

  const selectedRoles = useMemo(
    () => roles.filter(r => formik.values.userRoles?.includes(r._id)),
    [roles, formik.values.userRoles],
  );

  const resourcesDefinitions = useMergedResourcesDefinition(data);

  const fixedCustomResourceRights = useFixedResourceRights(
    resourcesDefinitions,
    selectedRoles,
  );

  const fixedApps = useMemo(() => {
    return selectedRoles.flatMap(r =>
      r.applications.map(c => ({
        id: c._id,
        source: r.name,
      })),
    );
  }, [selectedRoles]);

  // Odebrání oprávnění označených rolí při jejich změně - aby nebylo zároveň přiřazené právo a zároveň nebylo i v roli, kterou má
  useEffect(() => {
    const userRoles = formik.values.userRoles ?? [];
    const newValues = formik.values.customResourceRights ?? [];
    const rightsFromSelectedRoles = roles
      .filter(r => userRoles.includes(r._id))
      .flatMap(r => r.customResourceRights);
    formik.setFieldValue(
      "customResourceRights",
      newValues.filter(
        r =>
          !rightsFromSelectedRoles.some(ur => isSameResourceRightsInput(r, ur)),
      ),
      true,
    );
  }, [fixedCustomResourceRights, formik.values.userRoles]);

  return (
    <FormGrid
      gridTemplateColumns="auto 1fr"
      style={{ gap: 100, height: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
        <SwitchGroup>
          {roles.map(r => (
            <FormikSwitch
              id={`role-${r._id}`}
              valueIsNumber
              key={r._id}
              field="userRoles"
              value={r._id}
              label={r.name}
              tooltip={r.description}
            />
          ))}
        </SwitchGroup>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
          }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: 8,
              alignItems: "center",
            }}>
            <Button
              icon={<ArrowCounterclockwise />}
              variant="secondary"
              onClick={() => {
                formik.setFieldValue("customResourceRights", [], true);
                formik.setFieldValue("applications", [], true);
              }}>
              {t("reset_all_by_group")}
            </Button>
            <Tooltipped tooltip={t("reset_all_by_group_hint")}>
              <InfoCircle />
            </Tooltipped>
            <Button
              variant="secondary"
              onClick={() => {
                alert("TODO");
              }}>
              {t("copy_from_another_user")}
            </Button>
          </div>
        </div>
      </div>
      {/* FIXME asi nahradit formik react-hook-forms? Pokud ne, změnit na useFormikContext namísto předávání*/}
      <RightsSettingsTab
        resourcesDefinitions={resourcesDefinitions}
        fixedCustomResourceRights={fixedCustomResourceRights}
        fixedApps={fixedApps}
      />
    </FormGrid>
  );
}
