import { FormikProps } from "formik";
import { UserFormData } from "../userData";
import {
  Button,
  FlowLayout,
  FormGrid,
  FormikDateField,
  FormikSelectField,
  FormikTextField,
  languages,
  SelectOption,
  Switch,
  useAppContext,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { graphql, useMutation } from "react-relay";
import { UserAccountFormPasswordResetMutation } from "@relay-generated/UserAccountFormPasswordResetMutation.graphql";
import { CopyPromptDialog } from "../CopyPromptDialog";

const resetUserPasswordMutation = graphql`
  mutation UserAccountFormPasswordResetMutation($id: UUID!) {
    resetUserPassword(userUid: $id) {
      url
    }
  }
`;

export function UserAccountForm({
  formik,
}: {
  formik: FormikProps<UserFormData>;
}) {
  const { t } = useTranslation();
  const appContext = useAppContext();
  const isNew = !formik.values.userUid;

  const [passwordResetUrl, setPasswordResetUrl] = useState<string>();
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);

  const [resetUserPasswordCommit] =
    useMutation<UserAccountFormPasswordResetMutation>(
      resetUserPasswordMutation,
    );

  function resetPassword() {
    if (formik.values.userUid) {
      resetUserPasswordCommit({
        variables: { id: formik.values.userUid },
        onCompleted: ({ resetUserPassword: { url } }) => {
          setPasswordResetUrl(url);
          setPasswordResetDialogOpen(true);
        },
      });
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 50 }}>
      <FormGrid gridTemplateColumns="repeat(4, minmax(100px, 1fr))">
        <FormikTextField
          formik={formik}
          label={t("user_name")}
          field="userName"
        />
        {isNew && (
          <FormikTextField
            formik={formik}
            label={t("password")}
            field="password"
            helperText={t("password_hint")}
          />
        )}
        <FormikDateField
          formik={formik}
          label={t("valid_to")}
          field="validTo"
        />
        <Switch
          id="chain-user"
          style={{ alignSelf: "flex-start", marginTop: 32 }}
          label={t("chain_user")}
          checked={formik.values["chainUser"] ?? false}
          onChange={checked => {
            const doIt = () => formik.setFieldValue("chainUser", checked);
            if (checked) {
              doIt();
            } else {
              appContext
                .confirm(
                  t("turn_off_chain_user_question", { hotelName: "TODO" }),
                )
                .then(confirmed => {
                  if (confirmed) {
                    doIt();
                  }
                });
            }
          }}
        />
        <FormikTextField
          formik={formik}
          label={t("first_name")}
          field="firstName"
          style={{ gridColumn: 1 }}
        />
        <FormikTextField
          formik={formik}
          label={t("last_name")}
          field="lastName"
        />
        <FormikTextField formik={formik} label={t("code")} field="code" />
        <FormikTextField
          formik={formik}
          label={t("primary_phone")}
          field="tel"
          style={{ gridColumn: 1 }}
        />
        <FormikTextField
          formik={formik}
          label={t("primary_email")}
          field="email"
        />
        <FormikSelectField formik={formik} label={t("language")} field="lang">
          {languages.map(l => (
            <SelectOption key={l} value={l}>
              {l}
            </SelectOption>
          ))}
        </FormikSelectField>
        <FormikTextField
          formik={formik}
          label={t("note")}
          field="note"
          multiline={3}
          style={{ gridColumn: "1 / 3" }}
        />
      </FormGrid>
      {!isNew && (
        <FlowLayout variant="compact2">
          <Button variant="secondary" onClick={() => resetPassword()}>
            {t("generate_link_for_password_reset")}
          </Button>
        </FlowLayout>
      )}
      {passwordResetUrl && (
        <CopyPromptDialog
          title={t("password_reset")}
          fieldLabel={t("url")}
          open={passwordResetDialogOpen}
          text={passwordResetUrl}
          onClose={() => setPasswordResetDialogOpen(false)}
        />
      )}
    </div>
  );
}
