import { RolesPageRolesQuery } from "@relay-generated/RolesPageRolesQuery.graphql";
import * as yup from "yup";
import { customResourceRightsSchema } from "../users/userData";
import { RoleInsertDialogQuery } from "@relay-generated/RoleInsertDialogQuery.graphql";
import { UserRoleInsert } from "@relay-generated/RoleInsertMutation.graphql";
import { UserRoleUpdate } from "@relay-generated/RoleUpdateMutation.graphql";
import { SystemRoles } from "@relay-generated/UserRightsFormDataQuery.graphql";

export type RoleTableItem = RolesPageRolesQuery["response"]["roleList"][number];
export type SystemUserRole =
  RoleInsertDialogQuery["response"]["systemRoleList"][number];
export type RoleFormData = Partial<UserRoleInsert & UserRoleUpdate>;

export const roleBaseSchema = (systemRoles: readonly SystemUserRole[]) =>
  yup.object({
    description: yup.string().nullable(),
    name: yup.string().required(),
    systemRole: yup
      .string()
      .nullable()
      .oneOf(systemRoles.map(r => r.code as SystemRoles)),
    customResourceRights: customResourceRightsSchema,
    applications: yup.array(yup.number().required()),
  });
