/**
 * @generated SignedSource<<6c7400493cb3d8fc0749d42050be6c3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardPageHotelInfoQuery$variables = {
  hotel: string;
};
export type DashboardPageHotelInfoQuery$data = {
  readonly hotelGet: {
    readonly companyName: string | null | undefined;
    readonly hotelName: string;
  } | null | undefined;
};
export type DashboardPageHotelInfoQuery = {
  response: DashboardPageHotelInfoQuery$data;
  variables: DashboardPageHotelInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hotel"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hoteluid",
        "variableName": "hotel"
      }
    ],
    "concreteType": "Hotel",
    "kind": "LinkedField",
    "name": "hotelGet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hotelName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardPageHotelInfoQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardPageHotelInfoQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d6d86a231c281446475013644ec29025",
    "id": null,
    "metadata": {},
    "name": "DashboardPageHotelInfoQuery",
    "operationKind": "query",
    "text": "query DashboardPageHotelInfoQuery(\n  $hotel: UUID!\n) {\n  hotelGet(hoteluid: $hotel) {\n    companyName\n    hotelName\n  }\n}\n"
  }
};
})();

(node as any).hash = "088d16dba4fedbbe5376750847b5b3d6";

export default node;
