import { ReactNode, useEffect, useMemo, useRef } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { Loading } from "h11-client-component-lib";
import { BFF_URI } from "./params";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedUser } from "@store/userSlice";
import { CurrentUserQuery } from "@relay-generated/CurrentUserQuery.graphql";
import { loginNeededHttpStatuses } from "@comm/relay";
import { oAuthLoginLink } from "@comm/oauth";
import { currentUserQuery } from "@shared/graphql/currentUser/CurrentUserQuery";
import { CurrentUserFragment$key } from "@relay-generated/CurrentUserFragment.graphql";
import { currentUserFragment } from "@shared/graphql/currentUser/CurrentUserFragment";
import { readInlineData } from "relay-runtime";
import { RootState } from "@store";

function LoggedUserProviderContent({
  children,
  queryRef,
}: {
  children: ReactNode;
  queryRef: PreloadedQuery<CurrentUserQuery>;
}) {
  const userGet = usePreloadedQuery<CurrentUserQuery>(
    currentUserQuery,
    queryRef,
  ).userGet!;

  const user = useMemo(
    () => readInlineData<CurrentUserFragment$key>(currentUserFragment, userGet),
    [userGet],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoggedUser(user));
  }, [user]);

  return children;
}

export function LoggedUserProvider({ children }: { children: ReactNode }) {
  const [queryRef, loadQuery] =
    useQueryLoader<CurrentUserQuery>(currentUserQuery);

  const userLoadSeq = useSelector((state: RootState) => state.user.loadSeq);

  const mounted = useRef(0);

  useEffect(() => {
    if (mounted.current < userLoadSeq) {
      mounted.current++;

      async function meCallback() {
        const meRes = await fetch(`${BFF_URI}/me`, {
          credentials: "same-origin",
        });

        // FIXME loading, dokud nemám uživatele, ne prázdný frame bez avatara

        if (meRes.ok) {
          const userUid = await meRes.text();
          loadQuery(
            {
              userId: userUid,
            },
            { fetchPolicy: "store-and-network" },
          );
        } else if (loginNeededHttpStatuses.includes(meRes.status)) {
          // FIXME nějaké hezké UI
          // FIXME hotel z kontextu
          window.location.href = oAuthLoginLink(
            "9de64306-2bb1-4d2b-aa5a-9a73c1e881c7",
          );
        }
      }

      meCallback();
    }
  }, [userLoadSeq]);

  return queryRef ? (
    <LoggedUserProviderContent queryRef={queryRef}>
      {children}
    </LoggedUserProviderContent>
  ) : (
    // TODO přidat nějaký text redirecting to login page a případné kliknutí, kdyby nešlo?
    <div
      style={{
        gridColumn: "1/3",
        gridRow: "1/3",
        alignItems: "center",
        height: "100%",
      }}>
      <Loading size={300} centerVertical />
    </div>
  );
}
