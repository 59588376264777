/**
 * @generated SignedSource<<1b7994424779e135ed7277e5fdbcb383>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RobotUserAccountFormResetTokenMutation$variables = {
  id: string;
};
export type RobotUserAccountFormResetTokenMutation$data = {
  readonly robotUserResetToken: {
    readonly token: string;
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
  };
};
export type RobotUserAccountFormResetTokenMutation = {
  response: RobotUserAccountFormResetTokenMutation$data;
  variables: RobotUserAccountFormResetTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "userUid",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "tokenReset"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "PayloadMessages_message",
    "selections": (v3/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RobotUserAccountFormResetTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TokenResetPayload",
        "kind": "LinkedField",
        "name": "robotUserResetToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "PayloadMessages_interface",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayloadMessage",
                    "kind": "LinkedField",
                    "name": "warnings",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "PayloadInterface",
                "abstractKey": "__isPayloadInterface"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RobotUserAccountFormResetTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TokenResetPayload",
        "kind": "LinkedField",
        "name": "robotUserResetToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PayloadMessage",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "type": "PayloadInterface",
            "abstractKey": "__isPayloadInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "beca7d0fd6e52a094c1a500a1915876c",
    "id": null,
    "metadata": {},
    "name": "RobotUserAccountFormResetTokenMutation",
    "operationKind": "mutation",
    "text": "mutation RobotUserAccountFormResetTokenMutation(\n  $id: UUID!\n) {\n  robotUserResetToken(tokenReset: {userUid: $id}) {\n    token\n    ...PayloadMessages_interface\n  }\n}\n\nfragment PayloadMessages_interface on PayloadInterface {\n  __isPayloadInterface: __typename\n  errors {\n    ...PayloadMessages_message\n  }\n  warnings {\n    ...PayloadMessages_message\n  }\n}\n\nfragment PayloadMessages_message on PayloadMessage {\n  code\n  text\n}\n"
  }
};
})();

(node as any).hash = "fb8379140ad0de1c3cb6d3bcbf6b0511";

export default node;
