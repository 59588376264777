import { useFormikContext } from "formik";
import { Button, FormGrid, FormikTextField } from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { OwnPasswordChangeFormData } from "../../userData";
import { generateStrongPassword } from "../../userUtil";

export function OwnPasswordChangeForm({ dialogOpen }: { dialogOpen: boolean }) {
  const { t } = useTranslation();

  const formik = useFormikContext<OwnPasswordChangeFormData>();

  const [passwordGenerated, setPasswordGenerated] = useState(false);

  useEffect(() => {
    setPasswordGenerated(false);
  }, [dialogOpen]);

  function generatePassword() {
    const pass = generateStrongPassword();
    formik.setFieldValue("newPassword", pass, true);
    formik.setFieldValue("newPasswordAgain", pass, true);
    setPasswordGenerated(true);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}>
      <FormGrid
        gridTemplateColumns="repeat(2, minmax(100px, 1fr))"
        style={{ flex: "1", minHeight: 0 }}>
        <FormikTextField
          type="password"
          style={{ gridColumn: "1/2" }}
          formik={formik}
          label={t("old_password")}
          field="oldPassword"
        />
        <FormikTextField
          type={passwordGenerated ? "text" : "password"}
          onChange={() => {
            setPasswordGenerated(false);
          }}
          style={{ gridColumn: "1/2" }}
          formik={formik}
          label={t("new_password")}
          field="newPassword"
        />
        <Button
          variant="secondary"
          style={{ marginTop: 24 }}
          onClick={() => generatePassword()}>
          {t("generate_strong_password")}
        </Button>
        <FormikTextField
          type="password"
          style={{ gridColumn: "1/2" }}
          formik={formik}
          label={t("new_password_again")}
          field="newPasswordAgain"
        />
      </FormGrid>
    </div>
  );
}
