import {
  commitMutation,
  readInlineData,
  UseMutationConfig,
  VariablesOf,
} from "react-relay";
import {
  Disposable,
  FragmentRefs,
  GraphQLTaggedNode,
  MutationConfig,
  MutationParameters,
} from "relay-runtime";
import {
  payloadInterfaceFragment,
  payloadMessageFragment,
} from "@shared/graphql/PayloadMessages";
import { PayloadMessages_message$key } from "@relay-generated/PayloadMessages_message.graphql";
import { PayloadMessages_interface$key } from "@relay-generated/PayloadMessages_interface.graphql";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";

// Must be of value object, so I'm enforced to "explain" what's returned
export type ChainResponsesTypeBase = { [x: string | number]: object | null };

export type BaseQuery = MutationParameters & {
  response: {
    [x: string]: {
      // For now, it's mandatory to implement PayloadMessages_interface
      readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
    };
  };
  variables: object;
};

type Call<
  QueryType extends BaseQuery,
  ChainResponsesType extends ChainResponsesTypeBase,
> =
  | FetchCall<ChainResponsesType>
  | MutationCommitCall<QueryType, ChainResponsesType>
  | MutationCall<QueryType, ChainResponsesType>;

export type MutationCall<
  QueryType extends BaseQuery,
  ChainResponsesType extends ChainResponsesTypeBase = never,
> = {
  id?: Extract<keyof ChainResponsesType, string>;
  environment: RelayModernEnvironment;
  mutation: GraphQLTaggedNode;
  variables:
    | VariablesOf<QueryType>
    | ((
        previousResponses: Partial<ChainResponsesType>,
      ) => VariablesOf<QueryType>);

  /**
   * Optional validation of returned result - i.e. if user exists in data and has ID after creation and extraction of wanted result
   * @returns false on error, true on success or object if successful and return data (to be set as a chain result)
   * @param result
   */
  validateAndExtract?: (
    result: QueryType["response"],
  ) => ChainResponsesType[keyof ChainResponsesType] | boolean;
};

export type MutationCommitCall<
  T extends BaseQuery,
  ChainResponsesType extends ChainResponsesTypeBase = never,
> = {
  id?: Extract<keyof ChainResponsesType, string>;
  commit: (config: UseMutationConfig<T>) => Disposable;
  variables:
    | VariablesOf<T>
    | ((previousResponses: Partial<ChainResponsesType>) => VariablesOf<T>);

  /**
   * @see MutationCall.validateAndExtract
   */
  validateAndExtract?: (
    result: T["response"],
  ) => ChainResponsesType[keyof ChainResponsesType] | boolean;
};

export type FetchCall<ChainResponsesType extends ChainResponsesTypeBase> = {
  id?: Extract<keyof ChainResponsesType, string>;
  fetch: (
    previousResponses: Partial<ChainResponsesType>,
  ) => Promise<ChainResponsesType[keyof ChainResponsesType] | void>;
};

async function executeCall<
  QueryType extends BaseQuery,
  ChainResponsesType extends ChainResponsesTypeBase,
>(
  call: Call<QueryType, ChainResponsesType>,
  previousResponses: Partial<ChainResponsesType>,
): Promise<CallResult<ChainResponsesType>> {
  if ("fetch" in call) {
    return await executeFetchCall(call, previousResponses);
  } else {
    return await executeMutationCall(call, previousResponses);
  }
}

async function executeFetchCall<
  ChainResponsesType extends ChainResponsesTypeBase,
>(
  call: FetchCall<ChainResponsesType>,
  previousResponses: Partial<ChainResponsesType>,
): Promise<CallResult<ChainResponsesType>> {
  return {
    data: (await call.fetch(previousResponses)) ?? null,
    warnings: [],
  };
}

async function executeMutationCall<
  QueryType extends BaseQuery,
  ChainResponsesType extends ChainResponsesTypeBase,
>(
  call:
    | MutationCommitCall<QueryType, ChainResponsesType>
    | MutationCall<QueryType, ChainResponsesType>,
  previousResponses: Partial<ChainResponsesType>,
): Promise<CallResult<ChainResponsesType>> {
  return await new Promise((resolve, reject) => {
    // Here is some strange type, but it's necessary because UseMutationConfig and MutationConfig aren't exactly compatible because of all the undefineds and nulls...
    const config: UseMutationConfig<QueryType> &
      Omit<MutationConfig<QueryType>, "mutation"> = {
      onError: error => {
        // Processing of non GraphQL errors (HTTP errors, BFF errors, communication errors)
        console.error("Error in onError");
        reject(new CallError("unexpected", error.message));
      },
      onCompleted: (response, errorsOrWarnings) => {
        const warnings: Message[] = [];

        // region Processing of unexpected GraphQL errors

        // Classify each item in 'errorsOrWarnings' as 'error' or 'warning' based on its severity
        const { nonResponseErrors, nonResponseWarnings } = (
          errorsOrWarnings ?? []
        ).reduce(
          (acc, e) => {
            if (["CRITICAL", "ERROR"].includes(e.severity ?? "ERROR")) {
              acc.nonResponseErrors.push({ text: e.message });
            } else {
              acc.nonResponseWarnings.push({ text: e.message });
            }
            return acc;
          },
          {
            nonResponseErrors: [] as Message[],
            nonResponseWarnings: [] as Message[],
          },
        );

        if (nonResponseErrors && nonResponseErrors.length > 0) {
          console.error("Error in onCompleted");
          reject(new CallError("unexpected", nonResponseErrors[0].text));
          return;
        }

        warnings.push(...nonResponseWarnings);

        // endregion

        // region Processing of expected errors (errors/warnings present in the response itself)
        for (const [k, v] of Object.entries(response)) {
          const payloadInterfaceData =
            readInlineData<PayloadMessages_interface$key>(
              payloadInterfaceFragment,
              v,
            );
          if (
            payloadInterfaceData.errors &&
            payloadInterfaceData.errors.length > 0
          ) {
            console.error(`Error in response ${k}`);

            // Only the first error is taken into account
            const errorData = readInlineData<PayloadMessages_message$key>(
              payloadMessageFragment,
              payloadInterfaceData.errors[0],
            );
            reject(new CallError("expected", errorData.text));
            return;
          }

          if (payloadInterfaceData.warnings) {
            payloadInterfaceData.warnings.forEach(w => {
              const warningData = readInlineData<PayloadMessages_message$key>(
                payloadMessageFragment,
                w,
              );

              warnings.push({ text: warningData.text });
            });
          }
        }
        //endregion

        let extractedData: ChainResponsesType[keyof ChainResponsesType] | null;

        // region Optional response validation
        if (call.validateAndExtract) {
          const validationResult = call.validateAndExtract(response);
          if (validationResult === false) {
            console.error("Error in validation");
            reject(new CallError("unexpected", "TODO"));
            return;
          } else if (validationResult === true) {
            extractedData = null;
          } else {
            extractedData = validationResult;
          }
        } else {
          extractedData = null;
        }
        // endregion

        resolve({ data: extractedData, warnings });
      },
      variables:
        typeof call.variables === "function"
          ? call.variables(previousResponses)
          : call.variables,
    };

    if ("commit" in call) {
      call.commit(config);
    } else {
      const { environment, mutation } = call;
      commitMutation(environment, {
        mutation,
        ...config,
      });
    }
  });
}

export const prepareApiCall = <
  QueryType extends BaseQuery,
  ChainResponsesType extends ChainResponsesTypeBase,
>(
  primaryCall: Call<QueryType, ChainResponsesType>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chainedCalls: Call<any, any>[] = [];
  return {
    chain: function <
      TT extends BaseQuery,
      ChainResponsesType extends ChainResponsesTypeBase,
    >(call: Call<TT, ChainResponsesType>) {
      chainedCalls.push(call);
      return this;
    },
    execute: async function (): Promise<ExecuteResult<ChainResponsesType>> {
      const responses: Partial<ChainResponsesType> = {};
      let warnings: Message[] = [];

      function processCallResult(
        idOrIndex: keyof ChainResponsesType | number,
        result: CallResult<ChainResponsesType>,
      ) {
        warnings = [...warnings, ...result.warnings];
        if (result.data) {
          responses[idOrIndex] = result.data;
        }
      }

      const g = await executeCall<QueryType, ChainResponsesType>(
        primaryCall,
        responses,
      );

      processCallResult(primaryCall.id ?? 0, g);

      // Short circuits on first error
      try {
        for (const [index, i] of chainedCalls.entries()) {
          processCallResult(i.id ?? index + 1, await executeCall(i, responses));
        }
      } catch (e) {
        console.error(e);
        let message: string;
        if (e instanceof CallError) {
          message = e.message;
        } else if (e instanceof Error) {
          message = e.message;
        } else {
          message = "TODO unknown error";
        }

        warnings = [...(warnings ?? []), { text: message }];
      }

      return {
        // Here it's forces as ChainResponsesType, because I assume that all responses were validated and extracted correctly
        responses: responses as ChainResponsesType,
        warnings: warnings.length > 0 ? warnings : undefined,
      };
    },
  };
};

export class CallError {
  public type;
  public message;

  constructor(type: "expected" | "unexpected", message: string) {
    this.type = type;
    this.message = message;
  }
}

type Message = {
  text: string;
};

type CallResult<ChainResponsesType extends ChainResponsesTypeBase> = {
  data: ChainResponsesType[keyof ChainResponsesType] | null;

  // isn't undefined for simplicity - never goes outside this file
  warnings: Message[];
};

export type ExecuteResult<ChainResponsesType extends ChainResponsesTypeBase> = {
  responses: ChainResponsesType;
  warnings?: Message[];
};
