import "./yupConfig";
import "./styles/App.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { RelayEnvironmentProvider } from "react-relay";
import "./i18n/i18n";
import { router } from "./router/router";
import { relayEnvironment } from "@comm/relay";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "@store";
import { HoresApp } from "h11-client-component-lib";

function H11App() {
  return (
    <HoresApp>
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: "cs" }} />
        <ReduxProvider store={store}>
          <RelayEnvironmentProvider environment={relayEnvironment}>
            <RouterProvider router={router} />
          </RelayEnvironmentProvider>
        </ReduxProvider>
      </HelmetProvider>
    </HoresApp>
  );
}

export default H11App;
