/**
 * @generated SignedSource<<c22193fb14f52445015d6b55e9bbe159>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RightsSettingsTabRolesQuery$variables = Record<PropertyKey, never>;
export type RightsSettingsTabRolesQuery$data = {
  readonly applicationListAvailable: ReadonlyArray<{
    readonly _id: number;
    readonly code: string;
    readonly name: string;
  }>;
};
export type RightsSettingsTabRolesQuery = {
  response: RightsSettingsTabRolesQuery$data;
  variables: RightsSettingsTabRolesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Application",
    "kind": "LinkedField",
    "name": "applicationListAvailable",
    "plural": true,
    "selections": [
      {
        "alias": "_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RightsSettingsTabRolesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RightsSettingsTabRolesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3aaef0d8bd0e2f619125559761faf283",
    "id": null,
    "metadata": {},
    "name": "RightsSettingsTabRolesQuery",
    "operationKind": "query",
    "text": "query RightsSettingsTabRolesQuery {\n  applicationListAvailable {\n    _id: id\n    code\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "904bf0502434a58ce6fcde41e2c74f25";

export default node;
