import { FormikProps } from "formik";
import { OwnProfileFormData } from "../../userData";
import {
  Button,
  FormGrid,
  FormikPhotoDropZone,
  FormikSelectField,
  FormikTextField,
  languages,
  SelectField,
  SelectOption,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { NotificationsGroupsPanel } from "../../forms/NotificationsGroupsPanel";
import { Key } from "react-bootstrap-icons";
import { OwnPasswordChangeDialog } from "../OwnPasswordChangeDialog";

export function OwnProfileForm({
  formik,
}: {
  formik: FormikProps<OwnProfileFormData>;
}) {
  const { t } = useTranslation();
  const [passwordChangeDialogOpen, setPasswordChangeDialogOpen] =
    useState(false);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 50 }}>
      <FormGrid gridTemplateColumns="repeat(4, minmax(100px, 1fr))">
        <FormikPhotoDropZone<OwnProfileFormData> field="avatarFile" />
        <FormikTextField
          formik={formik}
          label={t("first_name")}
          field="firstName"
          style={{ gridColumn: 1 }}
        />
        <FormikTextField
          formik={formik}
          label={t("last_name")}
          field="lastName"
        />
        <FormikTextField formik={formik} label={t("code")} field="code" />
        <FormikSelectField formik={formik} label={t("language")} field="lang">
          {languages.map(l => (
            <SelectOption key={l} value={l}>
              {l}
            </SelectOption>
          ))}
        </FormikSelectField>
        <FormikTextField
          style={{ gridColumn: 1 }}
          formik={formik}
          label={t("hotel_phone")}
          field="hotelTel"
        />
        <FormikTextField
          formik={formik}
          label={t("hotel_email")}
          field="hotelEmail"
          style={{ gridColumn: "2 / 4" }}
        />
        <FormikTextField
          formik={formik}
          label={t("insert_signature")}
          field="hotelSignature"
          multiline={3}
          placeholder={t("insert_signature_placeholder")}
          style={{ gridColumn: "1 / 3" }}
        />
        <NotificationsGroupsPanel style={{ gridColumn: "1" }} />
        <SelectField label={t("theme")} value="light" onChange={() => {}}>
          {/*FIXME*/}
          {["dark", "light"].map(l => (
            <SelectOption key={l} value={l}>
              {l}
            </SelectOption>
          ))}
        </SelectField>
        <Button
          variant="secondary"
          icon={<Key />}
          onClick={() => setPasswordChangeDialogOpen(true)}
          style={{
            alignSelf: "flex-start",
            justifySelf: "flex-start",
            marginTop: 24,
          }}>
          {t("change_password")}
        </Button>
      </FormGrid>
      <OwnPasswordChangeDialog
        open={passwordChangeDialogOpen}
        onClose={() => setPasswordChangeDialogOpen(false)}
      />
    </div>
  );
}
