import { configureStore } from "@reduxjs/toolkit";
import { CurrentUserDetail, reducer as userReducer } from "./userSlice";
import { useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// TODO nejde type inference?
export const useLoggedUser: () => CurrentUserDetail | undefined = () => {
  return useSelector((state: RootState) => state.user.value);
};
