import { graphql } from "react-relay";

export const usersQuery = graphql`
  query UsersQuery($active: Boolean) {
    userList(active: $active) {
      userUid
      firstName
      lastName
      code
      active
      validTo
      userRoles {
        _id: id
        name
      }
      interactiveLogin
      avatar {
        url
      }
    }
  }
`;
