import { FormikToggleTree } from "h11-client-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./ResourcesTree.scss";
import {
  isSameResourceRightsInput,
  ResourceRightsDetail,
  ResourceRightsInput,
} from "../../data/ResourceRightsData";

export interface ResourcesTreeProps {
  filterValue: string;
  filterFunc: (value: string) => boolean;

  // Fixní (neodebratelná) práva - aktuálně jen ta převzatá z rolí
  fixedCustomResourceRights: (ResourceRightsInput & {
    source: string | undefined;
  })[];
  resourcesDefinitions: ResourceRightsDetail[];
}

// Počítá s tím, že pracuje s entitou, která má field "customResourceRights"
export function ResourcesTree({
  filterValue,
  filterFunc,
  fixedCustomResourceRights,
  resourcesDefinitions,
}: ResourcesTreeProps) {
  const { t } = useTranslation();

  const resourcesDefinitionsTreeNodes = useMemo(() => {
    type IntermediateModel = {
      [x: string]: {
        [x: string]: {
          action: string;
          title: string;
          description: string;
        }[];
      };
    };

    const intermediateModel: IntermediateModel = {};

    resourcesDefinitions.forEach(r => {
      if (!intermediateModel[r.namespace]) {
        intermediateModel[r.namespace] = {};
      }

      if (!intermediateModel[r.namespace][r.resource]) {
        intermediateModel[r.namespace][r.resource] = [];
      }

      intermediateModel[r.namespace][r.resource].push(...r.actions);
    });

    return Object.entries(intermediateModel)
      .filter(([, resources]) =>
        Object.values(resources).some(
          r => r.filter(a => filterFunc(a.title)).length > 0,
        ),
      )
      .map(([namespace, resources]) => ({
        id: `${namespace}`,
        title: namespace,
        children: Object.entries(resources)
          .filter(
            ([, actions]) =>
              actions.filter(a => filterFunc(a.title)).length > 0,
          )
          .map(([resource, actions]) => ({
            id: `${namespace}-${resource}`,
            title: resource,
            children: actions
              .filter(a => filterFunc(a.title))
              .map(a => {
                const value = {
                  namespace,
                  resource,
                  action: a.action,
                };

                const firstFixedCustomResourceRight =
                  fixedCustomResourceRights.find((v: ResourceRightsInput) =>
                    isSameResourceRightsInput(v, value),
                  );

                const tooltip =
                  a.description || firstFixedCustomResourceRight ? (
                    <div className="resources-tree-resource-tooltip">
                      {firstFixedCustomResourceRight && (
                        <span>
                          {t("inherited_right", {
                            source: firstFixedCustomResourceRight.source,
                          })}
                        </span>
                      )}
                      {a.description && firstFixedCustomResourceRight && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                      {a.description && <span>{a.description}</span>}
                    </div>
                  ) : undefined;

                return {
                  id: `${namespace}-${resource}-${a.action}`,
                  title: a.title,
                  tooltip: tooltip,
                  value,
                  disabled: !!firstFixedCustomResourceRight,
                };
              }),
          })),
      }));
  }, [
    resourcesDefinitions,
    filterValue,
    fixedCustomResourceRights /* Musí tu být i tohle kvůli tooltipům */,
  ]);

  // TODO ne 400 max height, ale vyplnit dialog
  return (
    <FormikToggleTree
      style={{ overflow: "auto" }}
      // Shodné pro uživatele i role
      field="customResourceRights"
      nodes={resourcesDefinitionsTreeNodes}
      isCheckedFunc={(
        currentValues: ResourceRightsInput[] | undefined,
        value: ResourceRightsInput,
      ) =>
        [...(currentValues ?? []), ...fixedCustomResourceRights].some(
          (v: ResourceRightsInput) => isSameResourceRightsInput(v, value),
        ) ?? false
      }
      setCheckedFunc={(
        currentValues: ResourceRightsInput[],
        changedValues: ResourceRightsInput[],
        checked: boolean,
      ) => {
        const newValues = [...(currentValues ?? [])].filter(
          currentValue =>
            !changedValues.some(changedValue =>
              isSameResourceRightsInput(currentValue, changedValue),
            ),
        );

        if (checked) {
          newValues.push(...changedValues);
        }

        return newValues;
      }}
    />
  );
}
