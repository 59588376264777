import { graphql } from "react-relay";

export const roleInsertDialogQuery = graphql`
  query RoleInsertDialogQuery {
    systemRoleList {
      code
      name
    }

    hotelsResourcesDefinition {
      ...UserRightsResource_resource
    }
    fileStoreResourcesDefinition {
      ...UserRightsResource_resource
    }
    usersResourcesDefinition {
      ...UserRightsResource_resource
    }
    reservationResourcesDefinition {
      ...UserRightsResource_resource
    }
  }
`;
