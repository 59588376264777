/**
 * @generated SignedSource<<d7a91f0f4a066ac43c6d17611c5e4c75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemRoles = "ADMIN" | "HOUSEKEEPING" | "MANAGER" | "RECEPTION" | "RESERVATION" | "%future added value";
export type UserRightsFormDataQuery$variables = Record<PropertyKey, never>;
export type UserRightsFormDataQuery$data = {
  readonly fileStoreResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly hotelsResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly reservationResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
  readonly roleList: ReadonlyArray<{
    readonly _id: number;
    readonly applications: ReadonlyArray<{
      readonly _id: number;
    }>;
    readonly customResourceRights: ReadonlyArray<{
      readonly action: string;
      readonly namespace: string;
      readonly resource: string;
    }>;
    readonly description: string | null | undefined;
    readonly name: string;
    readonly systemRole: SystemRoles | null | undefined;
  }>;
  readonly usersResourcesDefinition: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRightsResource_resource">;
  }>;
};
export type UserRightsFormDataQuery = {
  response: UserRightsFormDataQuery$data;
  variables: UserRightsFormDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namespace",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resource",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserRole",
  "kind": "LinkedField",
  "name": "roleList",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceRights",
      "kind": "LinkedField",
      "name": "customResourceRights",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "applications",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemRole",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserRightsResource_resource"
  }
],
v7 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "UserRightsAction",
    "kind": "LinkedField",
    "name": "actions",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemRoles",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRightsFormDataQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "hotelsResourcesDefinition",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "fileStoreResourcesDefinition",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "usersResourcesDefinition",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "reservationResourcesDefinition",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserRightsFormDataQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "hotelsResourcesDefinition",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "fileStoreResourcesDefinition",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "usersResourcesDefinition",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRightsResource",
        "kind": "LinkedField",
        "name": "reservationResourcesDefinition",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2185883bef2639f5051ab1b37e93a2d",
    "id": null,
    "metadata": {},
    "name": "UserRightsFormDataQuery",
    "operationKind": "query",
    "text": "query UserRightsFormDataQuery {\n  roleList {\n    _id: id\n    name\n    customResourceRights {\n      namespace\n      resource\n      action\n    }\n    applications {\n      _id: id\n    }\n    systemRole\n    description\n  }\n  hotelsResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  fileStoreResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  usersResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n  reservationResourcesDefinition {\n    ...UserRightsResource_resource\n  }\n}\n\nfragment UserRightsResource_resource on UserRightsResource {\n  namespace\n  resource\n  actions {\n    action\n    title\n    description\n    systemRoles\n  }\n}\n"
  }
};
})();

(node as any).hash = "71a68689e56ae71801875fc76d1e8c0d";

export default node;
