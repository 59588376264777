import { createBrowserRouter, Navigate } from "react-router-dom";
import { OAuthCallbackPage } from "../pages/OAuthCallbackPage";
import { ReservationsPage } from "../pages/ReservationsPage";
import { DashboardPage } from "../pages/DashboardPage";
import { UsersPage } from "../pages/users/UsersPage";
import { H11AppFrame } from "../H11AppFrame";
import { RouteErrorBoundary } from "./error/RouteErrorBoundary";
import { RolesPage } from "../pages/roles/RolesPage";

export const router = createBrowserRouter([
  {
    path: "/login/*",
    children: [{ path: "callback", element: <OAuthCallbackPage /> }],
  },
  {
    path: "/",
    element: <H11AppFrame />,
    errorElement: <RouteErrorBoundary />,
    children: [
      { path: "/", index: true, element: <Navigate to="/user" /> },
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/reservation",
        element: <ReservationsPage />,
      },
      {
        path: "/user",
        element: <UsersPage />,
      },
      {
        path: "/role",
        element: <RolesPage />,
      },
    ],
  },
]);
