import { UserRightsResource_resource$data } from "@relay-generated/UserRightsResource_resource.graphql";
import { UserUpdate } from "@relay-generated/UserUpdateMutation.graphql";

export type ResourceRightsDetail = UserRightsResource_resource$data[number];

export type ResourceRightsInput = NonNullable<
  UserUpdate["customResourceRights"]
>[number];

export const isSameResourceRightsInput = (
  a: ResourceRightsInput,
  b: ResourceRightsInput,
) => {
  return (
    a.namespace === b.namespace &&
    a.resource === b.resource &&
    a.action === b.action
  );
};
