import { FormDialog } from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { FormikProvider } from "formik";
import { userBaseSchema, UserFormData } from "./userData";
import { useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { UserUpdateMutation } from "@relay-generated/UserUpdateMutation.graphql";
import * as yup from "yup";
import { UserForm } from "./forms/UserForm";
import { userUpdateMutation } from "./forms/graphql/UserUpdateMutation";
import { useApiForm } from "@shared/forms/apiForm";
import { MutationCall } from "@comm/comm";
import { executeUserCall, UserChainResponsesType } from "./userUtil";
import { UserQuery } from "@relay-generated/UserQuery.graphql";
import { userQuery } from "./graphql/UserQuery";
import { useLoggedUser } from "@store";
import { refreshLoggedUser } from "@store/userSlice";
import { useDispatch } from "react-redux";

const userUpdateSchema = userBaseSchema.clone().shape({
  userUid: yup.string().required(),
});

export function UserUpdateDialog({
  queryRef,
  onClose,
  open,
}: {
  open: boolean;
  onClose: (submitted?: boolean) => void;
  queryRef: PreloadedQuery<UserQuery>;
}) {
  // FIXME vyhodit "!" - co znamená, že GraphQL může vracet null nebo undefined?
  const existingUserDetail = usePreloadedQuery<UserQuery>(
    userQuery,
    queryRef,
  ).userGet!;
  const { t } = useTranslation();

  const loggedUser = useLoggedUser();

  const dispatch = useDispatch();

  // Přemapování aplikací a rolí na pole jejich ID
  const userInput = useMemo(() => {
    const {
      avatar,
      applications,
      userRoles,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      interactiveLogin,
      ...transitionedProps
    } = existingUserDetail;
    return {
      ...transitionedProps,
      avatarFile: avatar?.url,
      applications: applications.map(a => a._id),
      userRoles: userRoles.map(r => r._id),
    };
  }, [existingUserDetail]);

  // FIXME disable formuláře pokud submitting - nějaký overlay?
  const { formik, submitting } = useApiForm<
    UserFormData,
    typeof userUpdateSchema,
    UserChainResponsesType
  >({
    initialValues: userInput,
    messages: {
      success: t("user_saved"),
      withWarnings: t("user_saved_with_errors"),
    },
    resetDeps: [open],
    schema: userUpdateSchema,
    onSubmit: (values, relayEnvironment) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { avatarFile, ...valuesWithoutAvatarFile } = values;
      const userUpdateCall: MutationCall<
        UserUpdateMutation,
        UserChainResponsesType
      > = {
        id: "main",
        environment: relayEnvironment,
        mutation: userUpdateMutation,
        variables: {
          input: valuesWithoutAvatarFile,
        },
        validateAndExtract: result => {
          const userUid = result.userUpdate.user?.userUid;
          return userUid ? { userUid } : false;
        },
      };

      return executeUserCall(
        userUpdateCall,
        values,
        responses => responses["main"]!.userUid,
        relayEnvironment,
      );
    },
    onSuccess: responses => {
      onClose(true);

      const userUid = responses.main?.userUid;

      if (userUid && loggedUser?.userUid === userUid) {
        dispatch(refreshLoggedUser());
      }
    },
  });

  const isRobotUser = !existingUserDetail.interactiveLogin;

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose()}
        title={isRobotUser ? t("robot_user_edit") : t("user_edit")}
        onSubmit={formik.handleSubmit}>
        <UserForm formik={formik} robotUser={isRobotUser} />
      </FormDialog>
    </FormikProvider>
  );
}
