import "./sentryConfig";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import H11App from "./H11App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <StrictMode>
    <H11App />
  </StrictMode>,
);
