import { Avatar, AvatarProps } from "h11-client-component-lib";

type UserAvatarProps = Omit<AvatarProps, "src" | "text"> & {
  user: {
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      url?: string | null;
    } | null;
  };
};

export function UserAvatar({
  user: { avatar, firstName, lastName },
  ...restProps
}: UserAvatarProps) {
  const text = firstName && lastName ? firstName[0] + lastName[0] : undefined;
  return <Avatar src={avatar?.url ?? undefined} text={text} {...restProps} />;
}
