import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useLoggedUser } from "@store";
import { useState } from "react";
import { LoggedUserProvider } from "./LoggedUserProvider";
import {
  Dropdown,
  Frame,
  MenuItem,
  SideMenu,
  SubMenu,
  Suspense,
  TopBar,
  useAppContext,
} from "h11-client-component-lib";
import { useTranslation } from "react-i18next";
import { logout } from "@comm/oauth";
import { OwnProfileUpdateDialog } from "./pages/users/own/OwnProfileUpdateDialog";
import { UserAvatar } from "@shared/ui/UserAvatar";

export function H11AppFrame() {
  const navigate = useNavigate();
  const context = useAppContext();
  const location = useLocation();
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();

  // FIXME nějak vyřešit, aby loggedUser byl dolů předáván jako vždy non-null?

  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);

  return (
    <Frame
      topBar={
        loggedUser && (
          <TopBar>
            <Dropdown>
              <Dropdown.Control>
                <div className="profile">
                  <UserAvatar user={loggedUser} size="medium" />
                  <span>{`${loggedUser.firstName} ${loggedUser.lastName}`}</span>
                </div>
              </Dropdown.Control>
              <Dropdown.Menu>
                <Dropdown.Item onSelect={() => setEditProfileDialogOpen(true)}>
                  {t("edit_profile")}
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() =>
                    // FIXME hotel z kontextu
                    logout("9de64306-2bb1-4d2b-aa5a-9a73c1e881c7").catch(() => {
                      context.notify(t("error_cannot_logout"), "danger");
                    })
                  }>
                  {t("logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </TopBar>
        )
      }
      sideMenu={
        loggedUser && (
          <SideMenu
            onItemSelected={id => {
              navigate(id);
            }}
            selectedItemId={location.pathname}>
            <MenuItem itemId={"dashboard"} label={t("dashboard")} />
            <MenuItem itemId={"reservation"} label={t("reservations")} />
            <SubMenu label={t("enums")} dropdownId="enums">
              <MenuItem itemId={"user"} label={t("users")} />
              <MenuItem itemId={"role"} label={t("roles")} />
            </SubMenu>
          </SideMenu>
        )
      }>
      <>
        <Suspense size={300}>
          <LoggedUserProvider>
            <Outlet />
          </LoggedUserProvider>
        </Suspense>
        {loggedUser && (
          /*FIXME ne UserUpdateDialog ale vlastní, osekaný dialog na editaci profilu*/
          <OwnProfileUpdateDialog
            open={editProfileDialogOpen}
            onClose={() => setEditProfileDialogOpen(false)}
          />
        )}
      </>
    </Frame>
  );
}
