import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { FormDialog, useAppContext } from "h11-client-component-lib";
import { roleBaseSchema, RoleFormData } from "./roleData";
import { RoleForm } from "./RoleForm";
import * as yup from "yup";
import { roleUpdateMutation } from "./graphql/RoleUpdateMutation";
import { RoleUpdateMutation } from "@relay-generated/RoleUpdateMutation.graphql";
import { RoleUpdateDialogQuery } from "@relay-generated/RoleUpdateDialogQuery.graphql";
import { roleUpdateDialogQuery } from "./graphql/RoleUpdateDialogQuery";
import { useMergedResourcesDefinition } from "@shared/ui/resources/resourcesUtil";

export function RoleUpdateDialog({
  open,
  onClose,
  queryRef,
}: {
  open: boolean;
  onClose: (submitted?: boolean) => void;
  queryRef: PreloadedQuery<RoleUpdateDialogQuery>;
}) {
  const { t } = useTranslation();

  const [commit] = useMutation<RoleUpdateMutation>(roleUpdateMutation);

  const { notify } = useAppContext();

  const data = usePreloadedQuery(roleUpdateDialogQuery, queryRef);

  const existingRoleDetail = useMemo(() => data.roleGet!, [data]);

  // Přemapování aplikací a rolí na pole jejich ID
  const roleInput = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...transitionedProps } = existingRoleDetail;
    return {
      id: _id,
      ...transitionedProps,
      applications: existingRoleDetail.applications.map(a => a._id),
    };
  }, [existingRoleDetail]);

  const systemRoles = useMemo(() => data.systemRoleList!, [data]);

  const resourcesDefinitions = useMergedResourcesDefinition(data);

  const roleUpdateSchema = useMemo(
    () =>
      roleBaseSchema(systemRoles)
        .clone()
        .shape({ id: yup.number().required() }),
    [systemRoles],
  );

  const formik = useFormik<RoleFormData>({
    initialValues: roleInput,
    validationSchema: roleUpdateSchema,
    validateOnMount: true,
    onSubmit: values => {
      const validInput = roleUpdateSchema.validateSync(values, {
        stripUnknown: true,
      });

      commit({
        variables: {
          input: validInput,
        },
        onCompleted: () => {
          notify(t("role_saved"), "success");
          onClose(true);
        },
        // FIXME
        onError: error => alert("An error occurred:" + error),
      });
    },
  });

  console.log(formik.errors);

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setValues(roleInput, true);
    }
  }, [open]);

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose()}
        title={t("role_insert")}>
        <RoleForm
          systemRoles={systemRoles}
          resourcesDefinitions={resourcesDefinitions}
        />
      </FormDialog>
    </FormikProvider>
  );
}
