import { graphql } from "react-relay";

export const userSetOwnAvatarMutation = graphql`
  mutation UserSetOwnAvatarMutation($fileUpload: FileUploadRequest!) {
    userSetOwnAvatar(fileUpload: $fileUpload) {
      fileUploadResponse {
        uploadUrl
      }
      ...PayloadMessages_interface
    }
  }
`;
