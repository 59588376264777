/**
 * @generated SignedSource<<68d13b91becacb7455f53b895bc96de8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserQuery$variables = {
  id: string;
};
export type UserQuery$data = {
  readonly userGet: {
    readonly applications: ReadonlyArray<{
      readonly _id: number;
    }>;
    readonly avatar: {
      readonly url: string;
    } | null | undefined;
    readonly chainUser: boolean;
    readonly code: string;
    readonly customResourceRights: ReadonlyArray<{
      readonly action: string;
      readonly namespace: string;
      readonly resource: string;
    }>;
    readonly email: string | null | undefined;
    readonly firstName: string | null | undefined;
    readonly hotelEmail: string | null | undefined;
    readonly hotelSignature: string | null | undefined;
    readonly hotelTel: string | null | undefined;
    readonly interactiveLogin: boolean;
    readonly lang: string | null | undefined;
    readonly lastName: string | null | undefined;
    readonly note: string | null | undefined;
    readonly tel: string | null | undefined;
    readonly userName: string;
    readonly userRoles: ReadonlyArray<{
      readonly _id: number;
    }>;
    readonly userUid: string;
    readonly validTo: string;
  } | null | undefined;
};
export type UserQuery = {
  response: UserQuery$data;
  variables: UserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": "_id",
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userUid",
        "variableName": "id"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "userGet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userUid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validTo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chainUser",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "note",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interactiveLogin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRole",
        "kind": "LinkedField",
        "name": "userRoles",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hotelTel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hotelEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hotelSignature",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResourceRights",
        "kind": "LinkedField",
        "name": "customResourceRights",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "namespace",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "applications",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ba6bba789d133775acc3a9826ad64e8c",
    "id": null,
    "metadata": {},
    "name": "UserQuery",
    "operationKind": "query",
    "text": "query UserQuery(\n  $id: UUID!\n) {\n  userGet(userUid: $id) {\n    userUid\n    userName\n    validTo\n    chainUser\n    firstName\n    lastName\n    code\n    tel\n    email\n    lang\n    note\n    interactiveLogin\n    userRoles {\n      _id: id\n    }\n    hotelTel\n    hotelEmail\n    hotelSignature\n    customResourceRights {\n      namespace\n      resource\n      action\n    }\n    avatar {\n      url\n    }\n    applications {\n      _id: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa2b1eb9d3b5120895b307860254796e";

export default node;
