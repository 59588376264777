import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";
import { BFF_URI } from "../params";
import { oAuthLoginLink } from "@comm/oauth";
import { useTranslation } from "react-i18next";
import { Loading } from "h11-client-component-lib";

// TODO nebude "snažší" mít redirect přímo do BFF?
export function OAuthCallbackPage() {
  const [searchParams] = useSearchParams();
  const code = useMemo(() => searchParams.get("code"), [searchParams]);

  /* Pozor react rerenderuje a zároveň volá useEffect 2x i když se nezměnili závislosti,
     pokud jsem ve StrictMode a development! Proto 
  */
  const callbackSent = useRef(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (!callbackSent.current) {
      callbackSent.current = true;
      fetch(`${BFF_URI}/oauth/callback`, {
        redirect: "follow",
        method: "POST",
        credentials: "same-origin",
        body: code,
      })
        .then(res => {
          if (res.ok) {
            // FIXME, zatím takhle "nešikovně", možná nejde jinak. Alespoň nějakou hlášku - you're beiing redirected?
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            throw Error();
          }
        })
        .catch(() => {
          // FIXME hotel z kontextu
          window.location.href = oAuthLoginLink(
            "9de64306-2bb1-4d2b-aa5a-9a73c1e881c7",
          );
        });
    }
  }, [code]);

  return <Loading text={t("redirecting_to_app")} centerVertical />;
}
