/**
 * @generated SignedSource<<783e7022996b3474a0b9acc3cfffd14f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UsersQuery$variables = {
  active?: boolean | null | undefined;
};
export type UsersQuery$data = {
  readonly userList: ReadonlyArray<{
    readonly active: boolean;
    readonly avatar: {
      readonly url: string;
    } | null | undefined;
    readonly code: string;
    readonly firstName: string | null | undefined;
    readonly interactiveLogin: boolean;
    readonly lastName: string | null | undefined;
    readonly userRoles: ReadonlyArray<{
      readonly _id: number;
      readonly name: string;
    }>;
    readonly userUid: string;
    readonly validTo: string;
  }>;
};
export type UsersQuery = {
  response: UsersQuery$data;
  variables: UsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "active"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "active",
        "variableName": "active"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "userList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userUid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validTo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserRole",
        "kind": "LinkedField",
        "name": "userRoles",
        "plural": true,
        "selections": [
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interactiveLogin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2c6f05cd15f110657b15fed4d4e9216c",
    "id": null,
    "metadata": {},
    "name": "UsersQuery",
    "operationKind": "query",
    "text": "query UsersQuery(\n  $active: Boolean\n) {\n  userList(active: $active) {\n    userUid\n    firstName\n    lastName\n    code\n    active\n    validTo\n    userRoles {\n      _id: id\n      name\n    }\n    interactiveLogin\n    avatar {\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f200df51be03dc551a93cd185af52f8";

export default node;
