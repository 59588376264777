import { useRouteError } from "react-router-dom";
import { LoginNeededError } from "@comm/relay";
import { logout, oAuthLoginLink } from "@comm/oauth";
import { useTranslation } from "react-i18next";
import ErrorIcon from "./error.svg";
import "./error.scss";
import { Button, useAppContext } from "h11-client-component-lib";

// FIXME do knihovny, včetně ikonek
export function RouteErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation();
  const context = useAppContext();

  if (error instanceof LoginNeededError) {
    // FIXME hotel z kontextu
    window.location.href = oAuthLoginLink(
      "9de64306-2bb1-4d2b-aa5a-9a73c1e881c7",
    );
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          fontSize: "2rem",
        }}>
        {t("redirecting_to_login_page")}
      </div>
    );
  } else {
    console.error(error);
    console.dir(error);
    return (
      <div className="error-page">
        <ErrorIcon />
        <span>{t("unexpected_error_occurred")}</span>
        <div style={{ display: "flex", gap: 32 }}>
          <Button
            variant="secondary"
            onClick={() => (window.location.href = "/")}>
            {t("reload")}
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              // FIXME hotel z kontextu
              logout("9de64306-2bb1-4d2b-aa5a-9a73c1e881c7").catch(() => {
                context.notify(t("error_cannot_logout"), "danger");
              })
            }>
            {t("logout")}
          </Button>
        </div>
      </div>
    );
  }
}
