import { FormDialog } from "h11-client-component-lib";
import { FormikProvider } from "formik";
import { OwnProfileFormData, userProfileBaseSchema } from "../userData";
import { useLoggedUser } from "@store";
import { useTranslation } from "react-i18next";
import { OwnProfileForm } from "./forms/OwnProfileForm";
import { CurrentUserDetail, refreshLoggedUser } from "@store/userSlice";
import { useDispatch } from "react-redux";
import { useApiForm } from "@shared/forms/apiForm";
import { FetchCall, MutationCall, prepareApiCall } from "@comm/comm";
import { createAvatarUploadCall, UserChainResponsesType } from "../userUtil";
import { createFileUploadParameters } from "@shared/utils/fileUtils";
import { UserSetOwnAvatarMutation } from "@relay-generated/UserSetOwnAvatarMutation.graphql";
import { userSetOwnAvatarMutation } from "./graphql/UserSetOwnAvatarMutation";
import { ownProfileUpdateMutation } from "./graphql/OwnProfileUpdateMutation";
import { OwnProfileUpdateMutation } from "@relay-generated/OwnProfileUpdateMutation.graphql";
import { useMemo } from "react";
import { currentUserFragment } from "@shared/graphql/currentUser/CurrentUserFragment";
import { CurrentUserFragment$key } from "@relay-generated/CurrentUserFragment.graphql";
import { readInlineData } from "react-relay";

const ownProfileUpdateSchema = userProfileBaseSchema.clone();

export type OnwProfileChainResponsesType = {
  "user-set-avatar": {
    uploadUrl: string;
  };
  main: {
    user: CurrentUserDetail;
  };
};

export function OwnProfileUpdateDialog({
  onClose,
  open,
}: {
  open: boolean;
  onClose: (submitted?: boolean) => void;
}) {
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Přemapování aplikací a rolí na pole jejich ID
  const userInput = useMemo(() => {
    if (loggedUser) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { avatar, ...transitionedProps } = loggedUser;
      return {
        ...transitionedProps,
        avatarFile: avatar?.url,
      };
    } else {
      return undefined;
    }
  }, [loggedUser]);

  // FIXME disable formuláře pokud submitting - nějaký overlay?
  const { formik, submitting } = useApiForm<
    OwnProfileFormData,
    typeof ownProfileUpdateSchema,
    OnwProfileChainResponsesType
  >({
    initialValues: userInput,
    messages: {
      success: t("profile_saved"),
      withWarnings: t("profile_saved_with_errors"),
    },
    resetDeps: [open],
    schema: ownProfileUpdateSchema,
    onSubmit: (values, relayEnvironment) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { avatarFile, ...valuesWithoutAvatarFile } = values;

      const ownProfileUpdateCall: MutationCall<
        OwnProfileUpdateMutation,
        OnwProfileChainResponsesType
      > = {
        id: "main",
        environment: relayEnvironment,
        mutation: ownProfileUpdateMutation,
        variables: {
          input: valuesWithoutAvatarFile,
        },
        validateAndExtract: result => {
          const user = result.userProfileOwnUpdate.user;
          if (user) {
            const userData = readInlineData<CurrentUserFragment$key>(
              currentUserFragment,
              user,
            );
            return { user: userData };
          }

          return false;
        },
      };

      const call = prepareApiCall(ownProfileUpdateCall);

      if (avatarFile instanceof File) {
        const userSetAvatarCall: MutationCall<
          UserSetOwnAvatarMutation,
          OnwProfileChainResponsesType
        > = {
          id: "user-set-avatar",
          environment: relayEnvironment,
          mutation: userSetOwnAvatarMutation,
          variables: {
            fileUpload: createFileUploadParameters(avatarFile),
          },
          validateAndExtract: result => {
            const uploadUrl =
              result.userSetOwnAvatar.fileUploadResponse?.uploadUrl;
            return uploadUrl ? { uploadUrl } : false;
          },
        };

        const avatarUploadCall: FetchCall<UserChainResponsesType> =
          createAvatarUploadCall(
            avatarFile,
            responses => responses["user-set-avatar"]!.uploadUrl,
          );

        call.chain(userSetAvatarCall).chain(avatarUploadCall);
      }

      return call.execute();
    },
    onSuccess: () => {
      onClose(true);
      dispatch(refreshLoggedUser());
    },
  });

  return (
    <FormikProvider value={formik}>
      <FormDialog
        open={open}
        onClose={() => onClose()}
        title={t("profile_edit")}
        onSubmit={formik.handleSubmit}>
        <OwnProfileForm formik={formik} />
      </FormDialog>
    </FormikProvider>
  );
}
