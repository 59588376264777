/**
 * @generated SignedSource<<172440bfe81a505620c5aabb8229a6f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayloadMessages_interface$data = {
  readonly errors: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_message">;
  }> | null | undefined;
  readonly warnings: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_message">;
  }> | null | undefined;
  readonly " $fragmentType": "PayloadMessages_interface";
};
export type PayloadMessages_interface$key = {
  readonly " $data"?: PayloadMessages_interface$data;
  readonly " $fragmentSpreads": FragmentRefs<"PayloadMessages_interface">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "PayloadMessages_interface"
};

(node as any).hash = "5d658f06c3e9aa101f4c42015229931a";

export default node;
